import * as React from 'react'
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon
} from '@heroicons/react/solid'
import classNames from 'classnames'

import usePagination, { DOTS } from 'hooks/usePagination'

const Pagination = ({ currentPage, totalCount, onPageChange }) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = (event) => {
    event.preventDefault()
    onPageChange(currentPage + 1)
  }

  const onPrevious = (event) => {
    event.preventDefault()
    onPageChange(currentPage - 1)
  }

  const lastPage = paginationRange[paginationRange.length - 1]

  return (
    <nav className="bg-white border-t border-gray-200 px-4 flex items-center justify-center sm:px-0 -mt-px">
      <a
        href="#"
        onClick={onPrevious}
        className={classNames({
          'border-t-2 border-transparent mr-5 py-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300':
            true,
          'pointer-events-none opacity-25': currentPage === 1
        })}
      >
        <ArrowNarrowLeftIcon
          className="mr-3 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        Previous
      </a>

      {paginationRange.map((pageNumber, idx) => {
        if (pageNumber === DOTS) {
          return (
            <span key={idx} className="border-transparent text-gray-400 border-t-2 py-4 px-4 inline-flex items-center text-sm font-medium">
              &#8230;
            </span>
          )
        }

        return (
          <a
            key={idx}
            href="#"
            onClick={(event) => {
              event.preventDefault()
              onPageChange(pageNumber)
            }}
            className={classNames({
              'border-t-2 py-4 px-4 inline-flex items-center text-sm font-medium':
                true,
              'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                pageNumber !== currentPage,
              'border-indigo-500 text-indigo-600': pageNumber === currentPage
            })}
          >
            {pageNumber}
          </a>
        )
      })}

      <a
        href="#"
        onClick={onNext}
        className={classNames({
          'border-t-2 border-transparent ml-5 py-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300':
            true,
          'pointer-events-none opacity-25': currentPage === lastPage
        })}
      >
        Next
        <ArrowNarrowRightIcon
          className="ml-3 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </a>
    </nav>
  )
}

export default Pagination
