import React from 'react'
import { useRouter } from 'next/router'
import Skeleton from 'react-loading-skeleton'

import { useToast } from 'hooks'

import { LOCAL_STORAGE_API_URL } from 'lib/gravy'
import ProxyAPI from 'lib/proxy'
import { load, save } from 'lib/storage'

import { Modal, Select } from 'components/ui'

interface ApiEndpointModalProps {
  onDismiss(): void
}

const ApiEndpointModal = (props: ApiEndpointModalProps) => {
  const { onDismiss } = props

  const toast = useToast()
  const router = useRouter()

  const [loading, setLoading] = React.useState<boolean>(true)
  const [apps, setApps] = React.useState<string[]>([])
  const [apiUrl, setApiUrl] = React.useState<string>(() =>
    load(LOCAL_STORAGE_API_URL)
  )

  const appsWithLocalhost = React.useMemo(() => {
    if (['staging', 'production'].includes(process.env.projectEnv)) {
      return apps
    }

    return ['http://localhost:3001/admin/api/', ...apps].reverse()
  }, [apps])

  const options = React.useMemo(() => {
    return appsWithLocalhost.map((url) => {
      return { value: url, text: url }
    })
  }, [appsWithLocalhost])

  const onConfirm = () => {
    save(LOCAL_STORAGE_API_URL, apiUrl)
    onDismiss()
    router.reload()
    toast.success('Successfully changed API endpoint')
  }

  React.useEffect(() => {
    ProxyAPI.get('apps').then((response) => {
      const appsWithAdminEndpoint = response.data.map(
        (url: string) => `${url}admin/api/`
      )
      setApps(appsWithAdminEndpoint)
      setLoading(false)
    })
  }, [])

  return (
    <Modal
      isOpen
      body={
        <div className="space-y-2">
          <p className="text-sm text-indigo-900 bg-indigo-100 p-3 rounded">
            Note: Changing this endpoint points all API requests to the new
            server. This means any data that you change on Admin will also be
            changed on the corresponding server.
          </p>

          {loading ? (
            <Skeleton
              height={38}
              width={460}
              style={{ marginTop: 8, borderRadius: 8 }}
            />
          ) : (
            <Select
              options={options}
              name="apiUrl"
              includeBlank={false}
              defaultValue={apiUrl}
              onChange={(event) => setApiUrl(event.target.value)}
            />
          )}
        </div>
      }
      onDismiss={onDismiss}
      onConfirm={onConfirm}
      confirmButtonColor="indigo"
      title="Change Endpoint"
    />
  )
}

export default ApiEndpointModal
