import React, { Ref, useMemo } from 'react'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import orderBy from 'lodash/orderBy'

import Select, { SelectProps } from 'components/ui/Select/Select'

const stages = {
  saver: 0,
  saver_manual: 1,
  buyer: 2,
  owner: 3
}

const UserHomebuyingStageSelect = React.forwardRef(
  (props: SelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const options = useMemo(() => {
      const array = []
      Object.keys(stages).forEach((stage) => {
        array.push({
          value: stages[stage],
          text: startCase(toLower(stage))
        })
      })

      return orderBy(array, ['text'])
    }, [])

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options} {...props} ref={ref} />
      </div>
    )
  }
)

export default UserHomebuyingStageSelect
