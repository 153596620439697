import React from 'react'

import { EngagementScore } from 'types/models'

interface EngagementScoreProps {
  engagementScore: EngagementScore
}

const EngagementScorePercentageCell: React.FC<EngagementScoreProps> = (props) => {
  const { engagementScore } = props

  if (!engagementScore) {
    return null
  }

  return (
    <span>{engagementScore.percentageScore}%</span>
  )
}

export default EngagementScorePercentageCell
