import React from 'react'
import classNames from 'classnames'

import { User } from 'types/models'

const sizeClass = {
  8: 'h-8 w-8',
  12: 'h-12 w-12'
}

type AvatarSizes = 8 | 12

interface Props {
  size?: AvatarSizes
  user: User
}

const Avatar: React.FC<Props> = (props) => {
  const {
    user: { firstName, lastName, avatarUrl },
    size
  } = props
  const name = [firstName, lastName].join(' ')
  const initials = name
    .match(/(\b\S)?/g)
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toUpperCase()

  if (avatarUrl) {
    return (
      <img
        className={classNames({
          'inline-block rounded-full': true,
          [sizeClass[size]]: true
        })}
        src={avatarUrl}
      />
    )
  }

  return (
    <span
      className={classNames({
        'inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500':
          true,
        [sizeClass[size]]: true
      })}
    >
      <span className="text-sm font-medium leading-none text-white">
        {initials}
      </span>
    </span>
  )
}

Avatar.defaultProps = {
  size: 8
}

export default Avatar
