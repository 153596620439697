import React, { Ref, useCallback, useEffect, useState } from 'react'
import useIsMounted from 'ismounted'

import { API } from 'lib/gravy'
import Select, { SelectProps } from 'components/ui/Select/Select'
import { EventType } from 'types/models'

const EventTypeSelect = React.forwardRef(
  (props: SelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const [eventTypes, setEventTypes] = useState<EventType[]>()
    const isMounted = useIsMounted()

    const options = useCallback(() => {
      return eventTypes.map((eventType) => {
        return { value: eventType.id, text: `${eventType.name}` }
      })
    }, eventTypes)

    useEffect(() => {
      API.get('event_types?q[s]=name').then((response) => {
        if (isMounted.current) {
          setEventTypes(response.data)
        }
      })
    }, [])

    if (!eventTypes) {
      return <Select {...props} />
    }

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options()} {...props} ref={ref} />
      </div>
    )
  }
)

export default EventTypeSelect
