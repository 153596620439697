import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import classNames from 'classnames'

interface Props {
  href: string
  title: string
}

const SidebarItem: React.FC<Props> = (props) => {
  const { href, title } = props

  const router = useRouter()
  const isActive = href === router.pathname

  return (
    <Link href={href}>
      <a
        className={classNames({
          'hover:text-gray-300 group flex items-center px-3 py-2.5 text-sm font-medium rounded-xl':
            true,
          'text-gray-400 hover:bg-gray-800 hover:bg-opacity-50': !isActive,
          'bg-gray-800 bg-opacity-75 text-gray-300': isActive
        })}
      >
        {title}
      </a>
    </Link>
  )
}

export default SidebarItem
