import React from 'react'
import Link from 'next/link'

import { Brokerage } from 'types/models'

interface BrokerageCellProps {
  brokerage: Brokerage
}

const BrokerageCell: React.FC<BrokerageCellProps> = (props) => {
  const { brokerage } = props

  if (!brokerage) {
    return null
  }

  return (
    <Link href={`/brokerage/${brokerage.id}`}>
      <a className="hover:text-indigo-600 flex items-center space-x-3">
        <span>
          {brokerage.partnerDetail.companyName}
        </span>
      </a>
    </Link>
  )
}

export default BrokerageCell
