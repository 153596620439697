import React, { Ref } from 'react'

import { Select } from 'components/ui'

interface BuyingTimelineSelectProps {
  name: string
}

const timelineOptions = [
  { text: '0-3 months', value: '0-3 months' },
  { text: '3-6 months', value: '3-6 months' },
  { text: '6+ months', value: '6+ months' },
  { text: 'I\'m not sure', value: 'I\'m not sure' }
]

const BuyingTimelineSelect = React.forwardRef(
  (
    props: BuyingTimelineSelectProps,
    ref: Ref<HTMLSelectElement>
  ): JSX.Element => {
    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={timelineOptions} {...props} ref={ref} />
      </div>
    )
  }
)

export default BuyingTimelineSelect
