import React from 'react'
import Link from 'next/link'
import { AddressCell } from 'components'
import { Property } from 'types/models'

interface PropertyCellProps {
  property: Property
}

const renderProperty = (property) => {
  return(
    <Link href={`/property/${property.id}`}>
      <a className="hover:text-indigo-600 flex items-center space-x-3">
        <span>
          <AddressCell address={property.address} /><br></br>
          Zillow: {property.zillowId ? property.zillowId : '---'}
        </span>
      </a>
    </Link>
  )
}

const PropertyCell: React.FC<PropertyCellProps> = (props) => {
  const { property } = props

  if (!property) {
    return <span>N/A</span>
  }

  return (
    renderProperty(property)
  )
}

export default PropertyCell
