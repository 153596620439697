import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilState, atom } from 'recoil'
import isEmpty from 'lodash/isEmpty'
import { useRouter } from 'next/router'
import { gray } from 'tailwindcss/colors'

import { Button, List, ListItem, Modal, Spinner, Tooltip } from 'components/ui'
import { Time } from 'components'
import { Shelf, Stack } from 'components/ui'
import { ArrowRight, Revert } from 'components/Icon'

import { API } from 'lib/gravy'

import { Version } from 'types/models'

import useToast from 'hooks/useToast'

const historyState = atom({
  key: 'historyState',
  default: null
})

interface Record {
  id: number
  type: string
}

const HistoryModal = (): React.ReactElement => {
  const router = useRouter()
  const toast = useToast()

  const [record, setRecord] = useRecoilState<Record>(historyState)

  const [loading, setLoading] = useState<boolean>(false)
  const [versions, setVersions] = useState<Version[]>([])

  const rollback = useCallback((versionId) => {
    API.post(`versions/${versionId}/rollback`)
      .then(() => {
        setRecord(null)
        setVersions([])
        toast.loading('Processing rollback...')
        router.reload()
      })
      .catch(() => {
        toast.success('Unable to process rollback. Validation failed.')
      })
  }, [])

  useEffect(() => {
    if (!record || !record.id || !record.type) {
      return
    }

    setLoading(true)
    API.get('versions', {
      params: {
        resource_type: record.type,
        resource_id: record.id
      }
    }).then((response) => {
      setLoading(false)
      setVersions(response.data)
    })
  }, [record])

  if (!record || !record.id || !record.type) {
    return null
  }

  return (
    <Modal
      isOpen
      onDismiss={() => {
        setRecord(null)
        setVersions([])
      }}
      title="Viewing History"
      size="large"
      body={
        loading ? (
          <div className="flex justify-center py-5">
            <Spinner />
          </div>
        ) : (
          <List>
            {versions.length > 0 ? (
              versions.map((version) => {
                const keys = Object.keys(version.changeset)

                if (keys.length === 0) {
                  return
                }

                return (
                  <ListItem
                    key={version.id}
                    text={
                      <Shelf gap={2} valign="center">
                        <Time value={version.createdAt} format="long" />

                        <Tooltip text="Rollback to here">
                          <Button
                            size="small"
                            theme="link"
                            onClick={() => rollback(version.id)}
                            icon={<Revert size={12} />}
                          />
                        </Tooltip>
                      </Shelf>
                    }
                    value={
                      <Stack gap={2}>
                        {keys
                          .filter((key) => {
                            const changeset = version.changeset[key]

                            const beforeIsPresent =
                              typeof changeset[0] === 'string' ||
                              changeset[0] === null
                                ? !isEmpty(String(changeset[0]))
                                : Object.keys(changeset[0]).length > 0
                            const afterIsPresent =
                              typeof changeset[1] === 'string' ||
                              changeset[1] === null
                                ? !isEmpty(String(changeset[1]))
                                : Object.keys(changeset[1]).length > 0

                            return beforeIsPresent || afterIsPresent
                          })
                          .map((key) => {
                            return (
                              <Stack key={key}>
                                <p className="font-semibold">{key}</p>

                                <Shelf valign="center" gap={2}>
                                  <span className="text-gray-600 inline-block line-through">
                                    {JSON.stringify(
                                      version.changeset[key][0]
                                    ) || '<empty>'}
                                  </span>
                                  <ArrowRight color={gray[500]} />
                                  <span>
                                    {JSON.stringify(
                                      version.changeset[key][1]
                                    ) || '<empty>'}
                                  </span>
                                </Shelf>
                              </Stack>
                            )
                          })}
                      </Stack>
                    }
                  />
                )
              })
            ) : (
              <div className="flex justify-center py-5">
                <p>No history</p>
              </div>
            )}
          </List>
        )
      }
    />
  )
}

export { HistoryModal as default, historyState }
