import React, { Ref } from 'react'

import Select, { SelectProps } from 'components/ui/Select/Select'

const TransferTypeSelect = React.forwardRef(
  (props: SelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const options = [
      { value: 'buy_a_home', text: 'Buy Home' },
      { value: 'deposit', text: 'Deposit' },
      { value: 'gift', text: 'Contribution' },
      { value: 'interest', text: 'Interest' },
      { value: 'cash_back', text: 'Cash Back' },
      { value: 'withdrawal', text: 'Withdrawal' }
    ]

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options} {...props} ref={ref} />
      </div>
    )
  }
)

export default TransferTypeSelect
