import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useDefaultSort = (defaultSort) => {
  const router = useRouter()

  useEffect(() => {
    if (router.query.s) {
      return null
    }

    if (defaultSort) {
      router.replace(`${router.asPath}?s=${defaultSort}`)
    }
  }, [])
}
