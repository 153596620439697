import React from 'react'
import * as Sentry from '@sentry/nextjs'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RecoilRoot } from 'recoil'

import useProgress from 'hooks/useProgress'

import { AuthProvider } from 'contexts/AuthContext'
import { ProtectedRoute } from 'contexts/AuthContext'
import { ToastContextProvider } from 'contexts/ToastContext'

import HistoryModal from 'components/common/HistoryModal'

import 'tailwindcss/tailwind.css'
import '@reach/dialog/styles.css'
import 'react-loading-skeleton/dist/skeleton.css'
import '../styles/globals.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  },
  logger: {
    log: (message) => {
      Sentry.captureMessage(message)
    },
    warn: (message) => {
      Sentry.captureMessage(message)
    },
    error: (error) => {
      Sentry.captureException(error)
    }
  }
})

function App({ Component, pageProps }: AppProps): JSX.Element {
  useProgress()

  return (
    <>
      <Head>
        <title>Gravy (Admin)</title>
      </Head>

      <ToastContextProvider>
        <AuthProvider>
          <div className="antialiased">
            <QueryClientProvider client={queryClient}>
              <RecoilRoot>
                <ProtectedRoute>
                  <Component {...pageProps} />

                  <HistoryModal />
                </ProtectedRoute>
              </RecoilRoot>
            </QueryClientProvider>
          </div>
        </AuthProvider>
      </ToastContextProvider>
    </>
  )
}

export default App
