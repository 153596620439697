import React, { Ref, useCallback, useEffect, useState } from 'react'
import useIsMounted from 'ismounted'

import { API } from 'lib/gravy'
import Select from 'components/ui/Select/Select'
import { KbaStatus } from 'types/models'
import { Control } from 'react-hook-form'

type KbaSelectProps = {
  name: string
  placeholder: string
  control: Control
  defaultValue: string
  useName: boolean
}

const KbaStatusSelect = React.forwardRef(
  (props: KbaSelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const [kbaStatuses, setKbaStatuses] = useState<KbaStatus[]>()
    const isMounted = useIsMounted()

    const options = useCallback(() => {
      return kbaStatuses.map((kbaStatus) => {
        return { value: props.useName ? kbaStatus.name : kbaStatus.value, text: `${kbaStatus.title}` }
      })
    }, [kbaStatuses])

    useEffect(() => {
      API.get('kba_statuses').then((response) => {
        if (isMounted.current) {
          setKbaStatuses(response.data)
        }
      })
    }, [])

    if (!kbaStatuses) {
      return <Select {...props} />
    }

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options()} {...props} ref={ref} />
      </div>
    )
  }
)

export default KbaStatusSelect
