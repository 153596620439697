import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { camelizeKeys } from 'humps'

import { LOCAL_STORAGE_TOKEN } from 'lib/gravy'

const ProxyAPI = axios.create({
  baseURL: process.env.proxyApiUrl,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json'
  }
})

ProxyAPI.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN)

    if (token) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${token}`
      }
    }

    return config
  },
  (error) => Promise.reject(error)
)

ProxyAPI.interceptors.response.use((response: AxiosResponse) => {
  if (response.data) {
    response.data = camelizeKeys(response.data)
  }

  return response
})

export default ProxyAPI
