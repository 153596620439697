import React, { Ref, useCallback, useEffect, useState } from 'react'
import useIsMounted from 'ismounted'

import { API } from 'lib/gravy'
import Select, { SelectProps } from 'components/ui/Select/Select'
import { CancelReason } from 'types/models'

const CancelReasonSelect = React.forwardRef(
  (props: SelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const [cancelReasons, setCancelReasons] = useState<CancelReason[]>()
    const isMounted = useIsMounted()

    const options = useCallback(() => {
      return cancelReasons.map((cancelReason) => {
        return { value: cancelReason.id, text: `${cancelReason.displayName}` }
      })
    }, [cancelReasons])

    useEffect(() => {
      API.get('cancel_reasons').then((response) => {
        if (isMounted.current) {
          setCancelReasons(response.data)
        }
      })
    }, [])

    if (!cancelReasons) {
      return <Select {...props} />
    }

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options()} {...props} ref={ref} />
      </div>
    )
  }
)

export default CancelReasonSelect
