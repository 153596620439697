import React, { Ref, useMemo } from 'react'
import orderBy from 'lodash/orderBy'

import Select, { SelectProps } from 'components/ui/Select/Select'

const statuses = {
  active: 0,
  paused: 1,
  cancelled: 2
}

const RecurringTransactionStatusSelect = React.forwardRef(
  (props: SelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const options = useMemo(() => {
      const array = []
      Object.keys(statuses).forEach((status) => {
        array.push({
          value: statuses[status],
          text: status
        })
      })

      return orderBy(array, ['text'])
    }, [])

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options} {...props} ref={ref} />
      </div>
    )
  }
)

export default RecurringTransactionStatusSelect
