import { useSetRecoilState } from 'recoil'

import { historyState } from 'components/common/HistoryModal'

const useHistory = () => {
  const setHistoryRecord = useSetRecoilState(historyState)

  const viewHistory = ({ type, id }: { type: string; id: any }) =>
    setHistoryRecord({
      type,
      id
    })

  return viewHistory
}

export default useHistory
