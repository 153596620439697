import { User } from 'types/models'

const toSentence = (arr: string[]) => {
  arr
    .reduce((prev, curr, i) => {
      return prev + curr + (i === arr.length - 2 ? ' and ' : ', ')
    }, '')
    .slice(0, -2)
}

const fullName = (user: User): string => {
  return `${user.firstName} ${user.lastName}`
}

export { toSentence, fullName }
