import React from 'react'
import Head from 'next/head'

interface PageHeaderProps {
  actions?: JSX.Element
  breadcrumbs?: JSX.Element
  title: string
}

const PageHeader = (props: PageHeaderProps): JSX.Element => {
  const { actions, breadcrumbs, title } = props

  return (
    <div className="bg-white border-b border-gray-200 p-6">
      <Head>
        <title>{title} | Gravy (Admin)</title>
      </Head>

      {breadcrumbs}

      <div className="md:flex md:items-center md:justify-between mt-2">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {title}
          </h2>
        </div>

        {actions && (
          <div className="mt-4 shrink-0 flex md:mt-0 md:ml-4">{actions}</div>
        )}
      </div>
    </div>
  )
}

export default PageHeader
