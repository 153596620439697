import React from 'react'
import classNames from 'classnames'

const gapSpacings = {
  1: 'w-1',
  2: 'w-2',
  3: 'w-3',
  4: 'w-4',
  5: 'w-5',
  6: 'w-6',
  7: 'w-7',
  8: 'w-8',
  9: 'w-9',
  10: 'w-10'
}

interface ItemProps {
  className?: string
  fill?: boolean
  fit?: boolean
  size?: number
}

export const Item: React.FC<ItemProps> = (props) => {
  return (
    <div
      className={`${props.className} ${classNames({
        'first:ml-0': true,
        'flex-auto max-w-full': props.fill && !props.fit,
        'flex-none': props.fit,
        [`${gapSpacings[props.size]}`]: typeof props.size !== 'undefined'
      })}`}
    >
      {props.children !== null ? props.children : '&#x200B;'}
    </div>
  )
}

Item.defaultProps = {
  fill: false,
  fit: false
}

const alignment = {
  left: 'justify-start',
  right: 'justify-end',
  center: 'justify-center',
  split: 'justify-between'
}

const valignment = {
  stretch: 'items-stretch',
  top: 'items-start',
  bottom: 'items-end',
  center: 'items-center',
  baseline: 'items-baseline'
}

const spacings = {
  1: 'ml-1',
  2: 'ml-2',
  3: 'ml-3',
  4: 'ml-4',
  5: 'ml-5',
  6: 'ml-6',
  7: 'ml-7',
  8: 'ml-8',
  9: 'ml-9',
  10: 'ml-10'
}

interface ShelfProps {
  align?: 'left' | 'right' | 'center' | 'split'
  gap?: number
  valign?: 'stretch' | 'top' | 'bottom' | 'center' | 'baseline'
  wrap?: boolean
  children: any
}

const Shelf = (props: ShelfProps): JSX.Element => {
  const { gap, valign, align, children, wrap } = props

  return (
    <div
      className={classNames({
        'flex w-full': true,
        [`${alignment[align]}`]: typeof align !== 'undefined',
        [`${valignment[valign]}`]: typeof valign !== 'undefined',
        'flex-wrap': wrap
      })}
    >
      {React.Children.map(children, (child, index) => {
        const classProps = {
          className: classNames({
            [`${spacings[gap]}`]: typeof gap !== 'undefined' && index !== 0,
            [`flex ${[`${valignment[valign]}`]}`]: typeof valign !== 'undefined'
          })
        }

        if (child !== null && child.type !== Item) {
          return <Item {...classProps}>{child}</Item>
        }

        if (child !== null) {
          return React.cloneElement(child, classProps)
        }

        return child
      })}
    </div>
  )
}

Shelf.Item = Item

export default Shelf
