import { useEffect, useRef } from 'react'

const useEffectAfterMount = (effect, deps) => {
  const hasMounted = useRef(false)
  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true
      return
    }
    effect()
  }, deps)
}

export default useEffectAfterMount
