import React, { Ref } from 'react'

import { Select } from 'components/ui'

interface FundingTypeSelectProps {
  name: string,
  placeholder: string,
  defaultValue: string
}

const states = [
  { text: 'Lender Funded', value: 'lender_funded' },
  { text: 'Agent Funded', value: 'agent_funded' }
]

const FundingTypeSelect = React.forwardRef(
  (
    props: FundingTypeSelectProps,
    ref: Ref<HTMLSelectElement>
  ): JSX.Element => {
    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={states} {...props} ref={ref} />
      </div>
    )
  }
)

export default FundingTypeSelect
