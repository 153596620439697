import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import Skeleton from 'react-loading-skeleton'
import { useTable } from 'react-table'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import times from 'lodash/times'
import { decamelize } from 'humps'
import { gray } from 'tailwindcss/colors'

import { Button, Input, Shelf } from 'components/ui'
import {
  CancelReasonSelect,
  IntegrationPartnerSelect,
  InvitationStatusSelect,
  LenderSelect,
  OperatingSystemSelect,
  PartnerInvitationLinkedSelect,
  PartnerInvitationUnlinkedSelect,
  PartnerSelect,
  PartnerTypeSelect,
  RecurringTransactionFrequencySelect,
  RecurringTransactionStatusSelect,
  RewardTypeSelect,
  TransferTypeSelect,
  UnderReviewSelect,
  UpgradeReasonSelect,
  UserAccountStatusSelect,
  UserHomebuyingStageSelect,
  UserSelect
} from 'components/selects'
import SortDesc from 'components/Icon/icons/SortDesc'
import SortAsc from 'components/Icon/icons/SortAsc'
import Pagination from 'components/Pagination/Pagination'
import { Close } from 'components/Icon'
import { useDefaultSort } from 'hooks/useDefaultSort'
import KbaStatusSelect from 'components/selects/KbaStatusSelect'

interface Props {
  columns: unknown
  data: unknown[]
  defaultSort?: string
  shouldDisableRow?(id: number): void
  page?: number
  resource?:
    | 'Affiliate'
    | 'Agent'
    | 'BankingProfile'
    | 'Blog'
    | 'Brokerage'
    | 'Cancellation'
    | 'Contact'
    | 'Contribution'
    | 'CreditUser'
    | 'DownPaymentGoal'
    | 'EventType'
    | 'FinancialAccount'
    | 'FinancialTransaction'
    | 'HomebuyerProfile'
    | 'HttpRequest'
    | 'Invitation'
    | 'IntegrationPartner'
    | 'Lender'
    | 'LenderPartner'
    | 'ManuallyTrackedAsset'
    | 'Mortgage'
    | 'MortgageRequest'
    | 'Queue'
    | 'PartnerInvitation'
    | 'PartnerRating'
    | 'PartnerSaver'
    | 'PartnerUser'
    | 'PlaidItem'
    | 'PremiumSubscription'
    | 'Property'
    | 'PropertyManager'
    | 'PushNotificationDevice'
    | 'RealEstateAgentRequest'
    | 'RecurringTransaction'
    | 'Rent'
    | 'Reward'
    | 'State License'
    | 'Transaction Limit'
    | 'User'
    | 'UserContributionSetting'
    | 'WaitlistedUser'
    | 'WebhookAllowlist'
    | 'WebhookService'
  searchKey?: string
  hasMore?: boolean
  totalCount?: number
  isFetching: boolean
  onPageChange?(page: number): void
  onRowClick?(record: Record<string, unknown>): void
  isSortable?: boolean
}

const userFilter = {
  name: 'user_id_eq',
  placeholder: 'User',
  Cell: UserSelect,
  controlled: true
}

const senderFilter = {
  name: 'sender_id_eq',
  placeholder: 'Sender',
  Cell: UserSelect,
  controlled: true
}

const operatingSystemFilter = {
  name: 'operating_system_eq',
  placeholder: 'Operating System',
  Cell: OperatingSystemSelect
}

const invitationStatusFilter = {
  name: 'status_eq',
  placeholder: 'Status',
  Cell: InvitationStatusSelect
}

const integrationPartnerFilter = {
  name: 'user_integration_metadata_integration_partner_id_eq',
  placeholder: 'Integration',
  Cell: IntegrationPartnerSelect
}

const kbaStatusFilter = {
  name: 'kba_status_eq',
  placeholder: 'KBA Status',
  Cell: KbaStatusSelect
}

const lenderFilter = {
  name: 'lender_id_eq',
  placeholder: 'Lender',
  Cell: LenderSelect
}

const partnerInvitationLinkedFilter = {
  name: 'linked',
  placeholder: 'Linked Filter',
  Cell: PartnerInvitationLinkedSelect
}

const partnerInvitationUnlinkedFilter = {
  name: 'unlinked',
  placeholder: 'Unlinked Filter',
  Cell: PartnerInvitationUnlinkedSelect
}

const partnerInvitePartnerTypeFilter = {
  name: 'invitable_type_eq',
  placeholder: 'Partner Type',
  Cell: PartnerTypeSelect
}

const partnerInvitePartnerIdFilter = {
  name: 'invitable_id_eq',
  placeholder: 'Partner',
  Cell: PartnerSelect
}

const partnerFilter = {
  name: 'partner_id_eq',
  placeholder: 'Partner',
  Cell: PartnerSelect
}

const blogPartnerFilter = {
  name: 'partner_id_or_attached_partners_id_eq',
  placeholder: 'Partner',
  Cell: PartnerSelect
}

const saverPartnerFilter = {
  name: 'referrable_id_eq',
  placeholder: 'Partner',
  Cell: PartnerSelect
}

const userHomebuyingStageFilter = {
  name: 'homebuying_stage_eq',
  placeholder: 'Homebuying Stage',
  Cell: UserHomebuyingStageSelect
}

const recurringTransactionFrequencyFilter = (name: string) => ({
  name: name,
  placeholder: 'Frequency',
  Cell: RecurringTransactionFrequencySelect
})

const recurringTransactionStatusFilter = (name: string) => ({
  name: name,
  placeholder: 'Status',
  Cell: RecurringTransactionStatusSelect
})

const userAccountStatusFilter = (name: string) => ({
  name: name,
  placeholder: 'User Account Status',
  Cell: UserAccountStatusSelect
})

const rewardTypeFilter = (name: string) => ({
  name: name,
  placeholder: 'Reward Type',
  Cell: RewardTypeSelect
})

const transferTypeFilter = (name: string) => ({
  name: name,
  placeholder: 'Transfer Type',
  Cell: TransferTypeSelect
})

const underReviewFilter = (name: string) => ({
  name: name,
  placeholder: 'Under Review?',
  Cell: UnderReviewSelect
})

const cancelReasonFilter = (name: string) => ({
  name: name,
  placeholder: 'Cancel Reason',
  Cell: CancelReasonSelect
})

const upgradeReasonFilter = (name: string) => ({
  name: name,
  placeholder: 'Upgrade Reason',
  Cell: UpgradeReasonSelect
})

const filters = {
  BankingProfile: [
    userFilter,
    userAccountStatusFilter('user_account_status_eq')
  ],
  Blog: [blogPartnerFilter],
  Cancellation: [
    userFilter,
    upgradeReasonFilter('upgrade_reasons_id_eq'),
    cancelReasonFilter('cancel_reasons_id_eq')
  ],
  Contribution: [userFilter],
  CreditUser: [userFilter, kbaStatusFilter],
  DownPaymentGoal: [userFilter],
  FinancialAccount: [userFilter],
  FinancialTransaction: [
    userFilter,
    transferTypeFilter('transfer_type_eq'),
    underReviewFilter('under_review_eq')
  ],
  Invitation: [senderFilter, userFilter, invitationStatusFilter],
  MortgageRequest: [lenderFilter],
  PartnerUser: [partnerFilter],
  PartnerInvitation: [
    partnerInvitePartnerTypeFilter,
    partnerInvitePartnerIdFilter,
    partnerInvitationLinkedFilter,
    partnerInvitationUnlinkedFilter
  ],
  PartnerRating: [partnerFilter],
  PartnerSaver: [
    userHomebuyingStageFilter,
    saverPartnerFilter,
    integrationPartnerFilter
  ],
  PlaidItem: [userFilter],
  PremiumSubscription: [userFilter],
  PushNotificationDevice: [userFilter, operatingSystemFilter],
  RecurringTransaction: [
    userFilter,
    recurringTransactionFrequencyFilter('frequency_eq'),
    recurringTransactionStatusFilter('status_eq')
  ],
  Rent: [userFilter],
  Reward: [userFilter, rewardTypeFilter('reward_type_eq')],
  User: [userHomebuyingStageFilter],
  WaitlistedUser: []
}

type SortDirections = 'asc' | 'desc'
const sortDirections: [SortDirections, SortDirections] = ['asc', 'desc']

const LoadingContent = () => {
  return times(5, (idx: number) => (
    <div
      key={idx}
      className="flex space-x-10 items-center px-5 py-2 overflow-hidden"
    >
      <Skeleton circle={true} height={34} width={34} />

      <div className="flex space-x-6 items-center">
        <Skeleton height={26} width={200} />
        <Skeleton height={26} width={80} />
        <Skeleton height={26} width={120} />
        <Skeleton height={26} width={120} />
        <Skeleton height={26} width={100} />
        <Skeleton height={26} width={80} />
        <Skeleton height={26} width={65} />
        <Skeleton height={26} width={140} />
        <Skeleton height={26} width={100} />
      </div>
    </div>
  ))
}

const IndexTable: React.FC<Props> = (props) => {
  const router = useRouter()
  const { s: sort } = router.query as { s: string }

  const {
    columns,
    data = [],
    defaultSort,
    shouldDisableRow,
    hasMore,
    totalCount,
    isFetching,
    isSortable = false,
    onPageChange,
    onRowClick,
    page,
    resource,
    searchKey
  } = props

  useDefaultSort(defaultSort)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data })

  const { control, register, reset, handleSubmit } = useForm()

  const onSubmit = useCallback(
    (formData) => {
      router.push({
        query: {
          ...router.query,
          ...formData,
          page: 1
        }
      })
    },
    [router.query]
  )

  const onSort = useCallback(
    (newSort) => {
      router.push({
        query: {
          ...router.query,
          s: newSort
        }
      })
    },
    [router.query]
  )

  const clearFilters = useCallback(() => {
    router.push({ query: null })
  }, [])

  const sortColumn = useMemo(() => {
    if (!sort) {
      return
    }

    return sort.split(' ')[0]
  }, [sort])

  const sortDirection = useMemo(() => {
    if (!sort) {
      return
    }

    return sort.split(' ')[1]
  }, [sort])

  const toggleSort = useCallback(
    (column) => {
      if (!sort) {
        onSort(`${column} asc`)
        return
      }

      let newSortDirection: 'asc' | 'desc'

      // same column, just changing direction
      if (sortColumn === column) {
        newSortDirection = sortDirections.find(
          (direction) => direction !== sortDirection
        )
      } else {
        newSortDirection = 'asc'
      }

      onSort(`${column} ${newSortDirection}`)
    },
    [sortColumn, sortDirection, router.query]
  )

  const renderSortIcon = useCallback(() => {
    switch (sortDirection) {
      case 'asc':
        return <SortAsc color={gray[500]} size={13} />
      case 'desc':
        return <SortDesc color={gray[500]} size={13} />
      default:
        return null
    }
  }, [sortDirection])

  useEffect(() => {
    reset(router.query)
  }, [router.query])

  return (
    <Fragment>
      {searchKey ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-6 py-4 bg-white flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
            <Input
              name={searchKey}
              placeholder="Search"
              icon="search"
              ref={register()}
            />

            {resource &&
              filters[resource]?.map((filter, idx) => {
                return (
                  <div className="w-full" key={idx}>
                    {filter.controlled ? (
                      <filter.Cell
                        name={filter.name}
                        placeholder={filter.placeholder}
                        control={control}
                      />
                    ) : (
                      <filter.Cell
                        name={filter.name}
                        placeholder={filter.placeholder}
                        ref={register}
                      />
                    )}
                  </div>
                )
              })}
            <div className="whitespace-nowrap">
              <Button
                type="submit"
                theme="secondary"
                className="w-full md:w-auto justify-center"
              >
                Filter
              </Button>
            </div>

            {Object.keys(router.query).length > 0 && (
              <div className="whitespace-nowrap">
                <Button
                  theme="link"
                  onClick={clearFilters}
                  className="w-full md:w-auto justify-center"
                  icon={<Close color={gray[400]} />}
                >
                  Clear Filters
                </Button>
              </div>
            )}
          </div>
        </form>
      ) : null}

      <div className="relative">
        {isFetching && data.length === 0 && <LoadingContent />}

        {data.length > 0 && (
          <div>
            <div className="overflow-x-auto">
              <div className="align-middle inline-block min-w-full border-b border-gray-200">
                <table
                  {...getTableProps()}
                  className="min-w-full divide-y divide-gray-200"
                >
                  <thead>
                    {headerGroups.map((headerGroup, idx) => (
                      <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, headerIdx) => {
                          const isColumnSortable =
                            isSortable &&
                            typeof column.skipSort === 'undefined' &&
                            column.key !== 'actions'

                          return (
                            <th
                              key={headerIdx}
                              {...column.getHeaderProps()}
                              className={classNames({
                                'py-3 text-left text-sm font-medium bg-white text-gray-900 leading-4 whitespace-nowrap':
                                  true,
                                'px-6': !column.sticky,
                                'sticky right-0 sticky-gradient': column.sticky
                              })}
                            >
                              <Shelf valign="center" gap={2}>
                                <span
                                  onClick={() =>
                                    isColumnSortable
                                      ? toggleSort(
                                          decamelize(
                                            column.sortAttribute || column.id
                                          )
                                        )
                                      : null
                                  }
                                  className={classNames({
                                    'cursor-pointer hover:text-indigo-700':
                                      isColumnSortable
                                  })}
                                >
                                  {column.render('Header')}
                                </span>

                                {isColumnSortable &&
                                sort &&
                                decamelize(
                                  column.sortAttribute || column.id
                                ) === sortColumn
                                  ? renderSortIcon()
                                  : null}
                              </Shelf>
                            </th>
                          )
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    {...getTableBodyProps()}
                    className={classNames({
                      'bg-white divide-y divide-gray-100 relative': true
                    })}
                  >
                    {rows.map((row, rowIdx) => {
                      prepareRow(row)
                      const isDisabled = shouldDisableRow
                        ? shouldDisableRow(row.original.id)
                        : false

                      return (
                        <tr
                          key={rowIdx}
                          {...row.getRowProps()}
                          className={classNames({
                            'hover:bg-gray-50': onRowClick,
                            loading: isFetching || isDisabled
                          })}
                        >
                          {row.cells.map((cell, cellIdx) => {
                            const clickProps: any = {}
                            if (onRowClick && !cell.column.skipRowClick) {
                              clickProps.onClick = () =>
                                onRowClick(row.original)
                            }
                            return (
                              <td
                                key={cellIdx}
                                {...cell.getCellProps()}
                                {...clickProps}
                                style={{ height: 57 }}
                                className={classNames({
                                  'py-3 whitespace-nowrap text-sm font-normal text-gray-600':
                                    true,
                                  'cursor-pointer':
                                    onRowClick && !cell.column.skipRowClick,
                                  'text-right': cell.column.align === 'right',
                                  'px-6': !cell.column.sticky,
                                  'px-4 sticky right-0 sticky-gradient':
                                    cell.column.sticky
                                })}
                              >
                                {cell.render('Cell')}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            {typeof hasMore !== 'undefined' ? (
              <>
                {(hasMore || page > 1) && (
                  <nav
                    className="bg-white px-4 py-3 md:rounded-b-lg flex items-center justify-between sm:px-6"
                    aria-label="Pagination"
                  >
                    <div className="flex-1 flex justify-between sm:justify-end">
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault()
                          onPageChange(page - 1)
                        }}
                        className={classNames({
                          'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50':
                            true,
                          'opacity-50 cursor-default pointer-events-none':
                            page === 1
                        })}
                      >
                        Previous
                      </a>
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault()
                          onPageChange(page + 1)
                        }}
                        className={classNames({
                          'ml-2 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50':
                            true,
                          'opacity-50 cursor-default pointer-events-none':
                            !hasMore
                        })}
                      >
                        Next
                      </a>
                    </div>
                  </nav>
                )}
              </>
            ) : (
              <Pagination
                currentPage={page}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </div>
        )}

        {data.length === 0 && !isFetching && (
          <div className="h-32 bg-white flex justify-center items-center">
            <svg
              className="mr-3 h-4 w-4 text-gray-400"
              x-description="Heroicon name: search"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="text-gray-600">No results</span>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default IndexTable
