import React from 'react'

import { Square } from 'components/Icon'

interface ColorCellProps {
  value?: string
  values?: string[]
}

const ColorCell: React.FC<ColorCellProps> = (props) => {
  const { value, values } = props

  if (value) {
    return(<Square color={value} size={48} />)
  } else if (values) {
    const squares = []

    values.forEach((value) => {
      squares.push(<Square color={value} size={48} />)
    })
    return(
      <div className="flex flex-row gap-6">{squares}</div>
    )
  }
}

export default ColorCell
