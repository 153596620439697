import React from 'react'
import Link from 'next/link'

import { User } from 'types/models'

interface ReferrerCellProps {
  user: User
}

const ReferrerCell: React.FC<ReferrerCellProps> = (props) => {
  const { user } = props

  if (!user || !user.referredById) {
    return <p className="text-gray-500 text-sm mt-4">N/A</p>
  }

  let url: string
  if (user.referredByType == 'User') {
    url = `/user/${user.id}`
  } else if (user.referredByType == 'LenderPartner') {
    url = `/lender-partner/${user.referredById}`
  } else {
    url = `/agent/${user.referredById}`
  }

  return (
    <Link href={url}>
      <a className="hover:text-indigo-600 flex items-center space-x-3">
        {user.referredByName} ({user.referredByType})
      </a>
    </Link>
  )
}

export default ReferrerCell
