import React from 'react'
import {
  Menu,
  MenuButton as ReachMenuButton,
  MenuItem,
  MenuItems,
  MenuList,
  MenuLink
} from '@reach/menu-button'

import Button from '../Button/Button'

const MenuButton = (props) => {
  const { size = 'medium', theme = 'link', ...otherProps } = props

  return (
    <ReachMenuButton as={Button} size={size} theme={theme} {...otherProps} />
  )
}

export { Menu, MenuButton, MenuItems, MenuItem, MenuList, MenuLink }
