import * as React from 'react'

import Select from 'components/selects/Select'
import { API } from 'lib/gravy'
import { Control } from 'react-hook-form'

type UserSelectProps = {
  autoFocus?: boolean
  name: string
  placeholder: string
  control: Control
  isClearable?: boolean
}

const UserSelect = (props: UserSelectProps) => {
  const fetchOptions = React.useCallback((params) => {
    params.per = 10
    params.q.s = 'first_name ASC'
    return API.get('users', { params })
  }, [])

  const buildOptions = React.useCallback(
    (options) =>
      options.map((option) => ({
        label: `${option.firstName} ${option.lastName}`,
        value: option.id
      })),
    []
  )

  return (
    <Select
      {...props}
      placeholder="Select a user"
      buildOptions={buildOptions}
      fetchOptions={fetchOptions}
      selectPredicate="full_name_cont"
    />
  )
}

export default UserSelect
