import React from 'react'
import Link from 'next/link'

import { Address } from 'types/models'

interface AddressCellProps {
  address: Address,
  linkPath?: string
}

const renderAddress = (address, linkPath) => {
  if (linkPath) {
    return(
      <Link href={linkPath}>
        <a className="hover:text-indigo-600 flex items-center space-x-3">
          <span>
            {address.street} {address.unit ? `Unit ${address.unit}` : null}<br></br>
            {address.city}, {address.state} {address.postalCode}
          </span>
        </a>
      </Link>
    )
  } else {
    return(
      <span>
        {address.street} {address.unit ? `Unit ${address.unit}` : null}<br></br>
        {address.city}, {address.state} {address.postalCode}
      </span>
    )
  }
}

const AddressCell: React.FC<AddressCellProps> = (props) => {
  const { address, linkPath } = props

  if (!address) {
    return <span>N/A</span>
  }

  return (
    renderAddress(address, linkPath)
  )
}

export default AddressCell
