import React, { Ref, useCallback, useEffect, useState } from 'react'
import useIsMounted from 'ismounted'

import { API } from 'lib/gravy'
import Select, { SelectProps } from 'components/ui/Select/Select'
import { Lender } from 'types/models'

const LenderSelect = React.forwardRef(
  (props: SelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const [lenders, setLenders] = useState<Lender[]>()
    const isMounted = useIsMounted()

    const options = useCallback(() => {
      return lenders.map((lender) => {
        return { value: lender.id, text: `${lender.name}` }
      })
    }, [lenders])

    useEffect(() => {
      API.get('lenders').then((response) => {
        if (isMounted.current) {
          setLenders(response.data)
        }
      })
    }, [])

    if (!lenders) {
      return <Select {...props} />
    }

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options()} {...props} ref={ref} />
      </div>
    )
  }
)

export default LenderSelect
