import React, { Ref, useCallback, useEffect, useState } from 'react'
import useIsMounted from 'ismounted'

import { API } from 'lib/gravy'
import Select, { SelectProps } from 'components/ui/Select/Select'
import { UserType } from 'types/models'

const UserTypeSelect = React.forwardRef(
  (props: SelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const [userTypes, setUserTypes] = useState<UserType[]>()
    const isMounted = useIsMounted()

    const options = useCallback(() => {
      return userTypes.map((userType) => {
        return { value: userType.id, text: userType.name }
      })
    }, userTypes)

    useEffect(() => {
      API.get('user_types?q[s]=name').then((response) => {
        if (isMounted.current) {
          setUserTypes(response.data)
        }
      })
    }, [])

    if (!userTypes) {
      return <Select {...props} />
    }

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options()} {...props} ref={ref} />
      </div>
    )
  }
)

export default UserTypeSelect
