import { LOCAL_STORAGE_USER } from 'lib/gravy'

interface Session {
  email: string
  name: string
  avatarUrl: string
}

const useSession = (): Session => {
  let user: Session
  const userData = localStorage.getItem(LOCAL_STORAGE_USER)

  if (userData) {
    user = JSON.parse(userData)
  }

  return { ...user }
}

export default useSession
