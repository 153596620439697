import React, { Ref, useCallback, useEffect, useState } from 'react'
import useIsMounted from 'ismounted'

import { API } from 'lib/gravy'
import Select, { SelectProps } from 'components/ui/Select/Select'
import { Brokerage } from 'types/models'

const BrokerageSelect = React.forwardRef(
  (props: SelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const [brokerages, setBrokerages] = useState<Brokerage[]>()
    const isMounted = useIsMounted()

    const options = useCallback(() => {
      return brokerages.map((brokerage) => {
        return { value: brokerage.id, text: `${brokerage.partnerDetail.companyName}` }
      })
    }, brokerages)

    useEffect(() => {
      API.get('brokerages?q[s]=name').then((response) => {
        if (isMounted.current) {
          setBrokerages(response.data)
        }
      })
    }, [])

    if (!brokerages) {
      return <Select {...props} />
    }

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options()} {...props} ref={ref} />
      </div>
    )
  }
)

export default BrokerageSelect
