import * as React from 'react'
import { useRouter } from 'next/router'

interface Query {
  page?: number
}

interface UseFiltersResponse {
  currentPage: number
  handlePageChange(page: number): void
  query: Query
}

const useFilters = (): UseFiltersResponse => {
  const router = useRouter()
  const { query } = router
  const page = parseInt(query.page as string, 10) || 1

  const handlePageChange = React.useCallback(
    (newPage: number) => {
      router.push({ query: { ...query, page: newPage } })
    },
    [query]
  )

  return { handlePageChange, query, currentPage: page }
}

export default useFilters
