import React from 'react'

interface Props {
  color?: string
  size?: number
}

const SortAsc = React.forwardRef(
  (
    { color = 'currentColor', size = 16, ...rest }: Props,
    ref: React.LegacyRef<SVGSVGElement>
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill={color}
        ref={ref}
        {...rest}
      >
        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
      </svg>
    )
  }
)

export default SortAsc
