import React from 'react'
import Link from 'next/link'

import { FinancialAccount } from 'types/models'

interface FinancialAccountCellProps {
  financialAccount: FinancialAccount
}

const FinancialAccountCell: React.FC<FinancialAccountCellProps> = (props) => {
  const { financialAccount } = props

  return (
    <Link href={`/financial-account/${financialAccount.id}`}>
      <a className="hover:text-indigo-600">{financialAccount.name}</a>
    </Link>
  )
}

export default FinancialAccountCell
