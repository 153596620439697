import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  Fragment
} from 'react'
import { Transition } from '@headlessui/react'

import { CheckCircleIcon, XCircleIcon, XIcon } from '@heroicons/react/solid'
import { Spinner } from 'components/ui'
import classNames from 'classnames'

const ToastContext = createContext(null)

type Toast = {
  id: string
  type: 'success' | 'error' | 'loading'
  message: string
  body?: string
  duration?: number
  dismissOnClick?: boolean
}

const genId = (() => {
  let count = 0
  return () => {
    return (++count).toString()
  }
})()

function ToastContextProvider({ children }) {
  const [toasts, setToasts] = useState<Toast[]>([])

  const addToast = useCallback((toast: Toast) => {
    const id = genId()
    setToasts((toasts) => [...toasts, { ...toast, id }])
    return id
  }, [])

  const dismissToast = (id: string) => {
    setToasts((prevToasts) =>
      prevToasts.filter((prevToast) => prevToast.id !== id)
    )
  }

  const removeToast = useCallback(() => {
    setToasts((toasts) => toasts.slice(1))
  }, [toasts])

  const renderIcon = useCallback((toast: Toast) => {
    switch (toast.type) {
      case 'success':
        return (
          <CheckCircleIcon
            className="h-6 w-6 text-emerald-400"
            aria-hidden="true"
          />
        )
      case 'error':
        return (
          <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
        )
      case 'loading':
        return (
          <div>
            <Spinner size={5} />
          </div>
        )
      default:
        break
    }
  }, [])

  useEffect(() => {
    if (toasts.length > 0) {
      const toast = toasts[toasts.length - 1]

      if (toast.duration !== Infinity) {
        const timer = setTimeout(
          () => setToasts((toasts) => toasts.slice(1)),
          toast.duration || 4000
        )
        return () => clearTimeout(timer)
      }
    }
  }, [toasts])

  return (
    <ToastContext.Provider value={{ addToast, dismissToast }}>
      {children}

      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {toasts.map((toast) => (
            <Transition
              key={toast.id}
              show
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
                onClick={() => {
                  if (toast.dismissOnClick) {
                    dismissToast(toast.id)
                  }
                }}
              >
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="shrink-0">{renderIcon(toast)}</div>
                    <div
                      className={classNames({
                        'ml-3 w-0 flex-1': true,
                        'pt-0.5': toast.type !== 'loading'
                      })}
                    >
                      <p className="text-sm font-medium text-gray-900">
                        {toast.message}
                      </p>

                      {toast.body && (
                        <div className="mt-1 text-sm text-gray-500">
                          {toast.body}
                        </div>
                      )}
                    </div>
                    <div className="ml-4 shrink-0 flex">
                      <button
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => {
                          removeToast()
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          ))}
        </div>
      </div>
    </ToastContext.Provider>
  )
}

export { ToastContext as default, ToastContextProvider }
