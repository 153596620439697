import React, { Ref, useMemo } from 'react'
import orderBy from 'lodash/orderBy'

import Select, { SelectProps } from 'components/ui/Select/Select'

const frequencies = {
  weekly: 0,
  biweekly: 1,
  monthly: 2
}

const RecurringTransactionFrequencySelect = React.forwardRef(
  (props: SelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const options = useMemo(() => {
      const array = []
      Object.keys(frequencies).forEach((frequency) => {
        array.push({
          value: frequencies[frequency],
          text: frequency
        })
      })

      return orderBy(array, ['text'])
    }, [])

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options} {...props} ref={ref} />
      </div>
    )
  }
)

export default RecurringTransactionFrequencySelect
