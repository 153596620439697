import React, { Ref, useCallback, useEffect, useState } from 'react'
import useIsMounted from 'ismounted'

import { API } from 'lib/gravy'
import Select from 'components/ui/Select/Select'
import { Contact } from 'types/models'

export interface ContactSelectProps {
  autoFocus?: boolean
  name: string
  includeBlank?: boolean
  placeholder?: string
  defaultValue?: string
  onChange?(value: any): void
  lenderId: string
}

const ContactSelect = React.forwardRef(
  (props: ContactSelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const {
      lenderId
    } = props

    const [contacts, setContacts] = useState<Contact[]>()
    const isMounted = useIsMounted()

    const options = useCallback(() => {
      return contacts.map((contact) => {
        return { value: contact.id, text: `${contact.firstName} ${contact.lastName}` }
      })
    }, contacts)

    useEffect(() => {
      API.get(`lenders/${lenderId}/contacts`).then((response) => {
        if (isMounted.current) {
          setContacts(response.data)
        }
      })
    }, [lenderId])

    if (!contacts) {
      return <Select {...props} />
    }

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options()} {...props} ref={ref} />
      </div>
    )
  }
)

export default ContactSelect
