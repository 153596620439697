import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { useSetRecoilState } from 'recoil'

import useSession from 'hooks/useSession'
import { useAuth } from 'contexts/AuthContext'

import { load } from 'lib/storage'
import { LOCAL_STORAGE_API_URL } from 'lib/gravy'

import Sidebar, { sidebarState } from '../Sidebar/Sidebar'
import { Menu, MenuButton, MenuItem, MenuList } from 'components/ui'
import { Hamburger } from 'components/Icon'

interface LayoutProps {
  layout?: 'padded' | 'flush'
  pageHeader?: JSX.Element
}
const Layout: React.FC<LayoutProps> = ({ children, pageHeader, layout }) => {
  const user = useSession()
  const setOpen = useSetRecoilState(sidebarState)
  const router = useRouter()
  const { logout } = useAuth()

  const currentEndpoint = load(LOCAL_STORAGE_API_URL)
  const isEndpointNoticeVisible = process.env.gravyApiUrl !== currentEndpoint

  const openSidebar = () => {
    setOpen(true)
  }

  if (!user.avatarUrl) {
    logout(() => router.push('/login'))
    return null
  }

  return (
    <div className="h-screen overflow-hidden">
      <div className="h-screen flex bg-gray-50">
        <Sidebar />

        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          {isEndpointNoticeVisible ? (
            <div className="p-2 bg-indigo-100 text-center border-b border-indigo-200 overflow-x-hidden">
              <p className="text-indigo-900 text-sm truncate">
                Connected to{' '}
                <span className="font-medium">
                  {new URL(currentEndpoint).hostname}
                </span>
              </p>
            </div>
          ) : null}

          <div className="relative z-10 shrink-0 flex h-16 bg-gray-900 lg:bg-white border-b border-gray-200 lg:hidden">
            <button
              className="px-4 border-r border-white border-opacity-25 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
              onClick={openSidebar}
            >
              <span className="sr-only">Open sidebar</span>
              <Hamburger color="#9e98b3" />
            </button>

            <div className="flex-1 flex justify-between px-4 sm:px-6 lg:px-8">
              <div className="flex-1 flex">
                <Link href="/users">
                  <a className="flex">
                    <Image
                      src="/wordmark.svg"
                      alt="Workflow"
                      height="24"
                      width="81.52"
                    />
                  </a>
                </Link>
              </div>
              <div className="flex items-center">
                <Menu>
                  <MenuButton theme="transparent">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full"
                      src={user.avatarUrl}
                      alt=""
                    />
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onSelect={() => logout(() => router.push('/login'))}
                    >
                      Sign out
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            {pageHeader}

            <div
              className={classNames({
                'md:p-6': layout === 'padded'
              })}
            >
              {children}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

Layout.defaultProps = {
  layout: 'padded'
}

export default Layout
