import React, { useMemo } from 'react'
import classNames from 'classnames'
import uniqueId from 'lib/uniqueId'

import Stack from 'components/ui/Stack/Stack'

interface FormGroupProps {
  horizontal?: boolean
  labelText: string
  visible?: boolean
  errors?: string[]
}

const FormGroup: React.FC<FormGroupProps> = (props) => {
  const { children, errors, horizontal, labelText, visible } = props
  const formFieldId = useMemo(() => uniqueId('field_'), [])
  const inputChildren = React.Children.toArray(children)
  const firstChild = React.cloneElement(
    inputChildren[0] as React.ReactElement,
    { id: formFieldId }
  )
  const divStyle = visible ? {} : {display: 'none'} 
  return (
    <div
      style={divStyle}
      className={classNames({
        'items-start': true,
        ['py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5']: horizontal,
        'py-1 mb-2': !horizontal
      })}
    >
      <dt
        className={classNames({
          'text-sm font-medium text-gray-500': true,
          'sm:mt-3': horizontal
        })}
      >
        <label
          htmlFor={formFieldId}
          className="block text-sm font-medium text-gray-700"
        >
          {labelText}
        </label>
      </dt>

      <div
        className={classNames({
          'mt-1 flex text-sm text-gray-900 sm:col-span-2': true,
          ['mt-0']: !horizontal
        })}
      >
        <Stack>
          {firstChild}
          {inputChildren.filter((_, i) => i !== 0)}

          {errors?.length > 0 && (
            <p className="mt-0.5 text-xs text-red-900 bg-red-200 rounded-md inline-block px-2 py-1 relative form-error">
              {errors.join(', ')}
            </p>
          )}
        </Stack>
      </div>
    </div>
  )
}

FormGroup.defaultProps = {
  horizontal: true,
  visible: true
}

export default FormGroup
