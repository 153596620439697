import React, { Ref, useCallback, useEffect, useState } from 'react'
import useIsMounted from 'ismounted'

import { API } from 'lib/gravy'
import Select, { SelectProps } from 'components/ui/Select/Select'
import { Partner } from 'types/models'

const PartnerSelect = React.forwardRef(
  (props: SelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const [partners, setPartners] = useState<Partner[]>()
    const isMounted = useIsMounted()

    const options = useCallback(() => {
      return partners.map((partner) => {
        return { value: partner.id, text: `${partner.name} (${partner.partnerType})` }
      })
    }, partners)

    useEffect(() => {
      API.get('all_partners?q[s]=name').then((response) => {
        if (isMounted.current) {
          setPartners(response.data)
        }
      })
    }, [])

    if (!partners) {
      return <Select {...props} />
    }

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options()} {...props} ref={ref} />
      </div>
    )
  }
)

export default PartnerSelect
