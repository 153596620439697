import classNames from 'classnames'

interface ButtonStyles {
  disabled?: boolean
  theme?: string
  size?: string
}

const useButtonStyles = ({
  theme = 'secondary',
  size = 'medium',
  disabled
}: ButtonStyles): string => {
  const themes = {
    primary:
      'border-transparent text-white bg-indigo-600 focus:ring-indigo-500 focus:ring-2 focus:ring-offset-2',
    secondary:
      'border-transparent text-indigo-700 bg-indigo-100 focus:ring-indigo-500 focus:ring-2 focus:ring-offset-2',
    link: 'shadow-none border-gray-200 text-gray-700 bg-white focus:ring-indigo-500 focus:ring-2 focus:ring-offset-2',
    danger:
      'border-transparent text-red-700 bg-red-100 focus:ring-red-500 focus:ring-2 focus:ring-offset-2',
    transparent: 'shadow-none border-none focus:shadow-none'
  }

  const enabledTheme = {
    primary: 'hover:bg-indigo-700',
    secondary: 'hover:bg-indigo-200',
    danger: 'hover:bg-red-200',
    link: 'hover:bg-gray-50'
  }

  const disabledTheme = {
    primary: 'hover:bg-indigo-600',
    secondary: 'hover:bg-indigo-100',
    danger: 'hover:bg-red-100',
    link: 'hover:bg-white'
  }

  const sizes = {
    small: 'leading-4 text-xs px-1.5 py-1.5',
    medium: 'text-sm px-4 py-2',
    large: 'text-base px-4 py-2',
    xlarge: 'text-lg px-6 py-3'
  }

  const buttonStyle = classNames({
    'inline-flex items-center border font-medium rounded-md focus:outline-none appearance-none transition-all':
      true,
    [`${sizes[size]}`]: true,
    [`${themes[theme]}`]: true,
    'disabled:opacity-50 disabled:cursor-default': disabled,
    [`${disabledTheme[theme]}`]: disabled,
    [`${enabledTheme[theme]}`]: !disabled
  })

  return buttonStyle
}

export default useButtonStyles
