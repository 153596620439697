import React from 'react'

import { Badge } from 'components/ui'

import { Invitation } from 'types/models'

const getColor = (status: string): 'gray' | 'emerald' | 'indigo' | 'red' => {
  switch (status) {
    case 'pending':
      return 'gray'
    case 'accepted':
      return 'emerald'
    case 'joined':
      return 'indigo'
    default:
      return 'gray'
  }
}

interface InvitationBadgeProps {
  invitation: Invitation
}

const InvitationBadge: React.FC<InvitationBadgeProps> = ({ invitation }) => (
  <Badge color={getColor(invitation.status)}>{invitation.status}</Badge>
)

export default InvitationBadge
