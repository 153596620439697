import React from 'react'
import { useRecoilState, atom } from 'recoil'

import { Modal } from 'components/ui'

export const deleteActionState = atom({
  key: 'deleteActionState',
  default: null
})

interface DeleteModalProps {
  handleDestroy(id: number): void
}

const DeleteModal = ({
  handleDestroy
}: DeleteModalProps): JSX.Element | null => {
  const [recordToDelete, setRecordToDelete] = useRecoilState(deleteActionState)

  if (!recordToDelete) {
    return null
  }

  return (
    <Modal
      isOpen
      onDismiss={() => setRecordToDelete(null)}
      onConfirm={() => {
        handleDestroy(recordToDelete.id)
        setRecordToDelete(null)
      }}
      title="Delete Record"
      body="Are you sure you want to delete this record?"
    />
  )
}

export default DeleteModal
