import React from 'react'

import styles from './Tooltip.module.css'

interface TooltipProps {
  text: string
  active?: boolean
  className?: string
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  active = true,
  text,
  className
}): React.ReactElement<any, any> => {
  if (!active) {
    return children as React.ReactElement
  }

  return (
    <div className={`${styles.tooltip} ${className}`} aria-label={text}>
      {children}
    </div>
  )
}

export default Tooltip
