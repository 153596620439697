import React, { Ref, useCallback} from 'react'
import Select from 'components/ui/Select/Select'
import { QuestionOption } from 'types/models'

export interface CorrectAnswerSelectProps {
  autoFocus?: boolean
  name: string
  includeBlank?: boolean
  placeholder?: string
  defaultValue?: string
  onChange?(value: any): void
  selectOptions: QuestionOption[]
}

const QuizQuestionCorrectAnswerSelect = React.forwardRef(
  (props: CorrectAnswerSelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const {
      selectOptions
    } = props

    const options = useCallback(() => {
      return selectOptions.map((option) => {
        return { value: option.id, text: option.value }
      })
    }, selectOptions)

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options()} {...props} ref={ref} />
      </div>
    )
  }
)

export default QuizQuestionCorrectAnswerSelect
