import React from 'react'
import Link from 'next/link'

import { Avatar } from '../ui'

import { User } from 'types/models'

interface UserCellProps {
  user: User
  withAvatar?: boolean
  onlyAvatar?: boolean
}

const UserCell: React.FC<UserCellProps> = (props) => {
  const { user, withAvatar = false, onlyAvatar = false } = props

  if (!user) {
    return null
  }

  return (
    <Link href={`/user/${user.id}`}>
      <a className="hover:text-indigo-600 flex items-center space-x-3">
        {(onlyAvatar || withAvatar) && <Avatar user={user} />}

        {onlyAvatar ? null : (
          <span>
            {user.firstName} {user.lastName}
          </span>
        )}
      </a>
    </Link>
  )
}

export default UserCell
