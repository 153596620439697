import React from 'react'

interface Props {
  color?: string
  size?: number
}

const Square = React.forwardRef(
  (
    { color = 'currentColor', size = 16, ...rest }: Props,
    ref: React.LegacyRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
        viewBox="0 0 512 512"
        stroke={color}
        {...rest}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M23.05 23.05V488.9H488.9V23.05H23.05z"
        />
      </svg>
    )
  }
)

export default Square
