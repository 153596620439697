import React from 'react'
import classNames from 'classnames'

const spacings = {
  '-3': '-mt-3',
  '-2': '-mt-2',
  '-1': '-mt-1',
  1: 'mt-1',
  2: 'mt-2',
  3: 'mt-3',
  4: 'mt-4',
  5: 'mt-5',
  6: 'mt-6',
  7: 'mt-7',
  8: 'mt-8',
  9: 'mt-9',
  10: 'mt-10'
}

interface Props {
  gap?: number
}

const Stack: React.FC<Props> = (props) => {
  const { gap, children } = props
  return (
    <div className="flex flex-col w-full">
      {React.Children.map(children, (child) => {
        if (child !== null) {
          const classProps = {
            className: classNames({
              [`${spacings[gap]}`]: typeof gap !== 'undefined',
              'w-full first:mt-0': true
            })
          }
          return <div {...classProps}>{child}</div>
        }

        return null
      })}
    </div>
  )
}

export default Stack
