import React, { Fragment } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useRecoilState, atom } from 'recoil'
import OutsideClickHandler from 'react-outside-click-handler'
import { SelectorIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { Menu, Transition } from '@headlessui/react'

import useSession from 'hooks/useSession'
import { useAuth } from 'contexts/AuthContext'

import SidebarItem from '../SidebarItem/SidebarItem'
import { Shelf } from '../ui'
import { Close } from 'components/Icon'
import ApiEndpointModal from './ApiEndpointModal'

export const sidebarState = atom({
  key: 'sidebarState',
  default: false
})

const sidebarItems = [
  { title: 'Allowlisted JWTs', href: '/allowlisted-jwts' },
  { title: 'Banking Profiles', href: '/banking-profiles' },
  { title: 'Blogs', href: '/blogs' },
  { title: 'Cancellations', href: '/cancellations' },
  { title: 'Contacts', href: '/contacts' },
  { title: 'Contributions', href: '/contributions' },
  { title: 'Credit Users', href: '/credit-users' },
  { title: 'Down Payment Goals', href: '/down-payment-goals' },
  { title: 'Financial Accounts', href: '/financial-accounts' },
  { title: 'Financial Transactions', href: '/financial-transactions' },
  { title: 'Gravy+ Subscriptions', href: '/premium-subscriptions' },
  { title: 'Homebuying Profiles', href: '/homebuying-profiles'},
  { title: 'Invitations', href: '/invitations' },
  { title: 'Lenders', href: '/lenders' },
  { title: 'Manually Tracked Assets', href: '/manually-tracked-assets' },
  { title: 'Mortgages', href: '/mortgages' },
  { title: 'Mortgage Requests', href: '/mortgage-requests' },
  { title: 'Plaid Items', href: '/plaid-items' },
  { title: 'Push Notification Devices', href: '/push-notification-devices' },
  { title: 'Real Estate Agent Requests', href: '/real-estate-agent-requests' },
  { title: 'Recurring Transactions', href: '/recurring-transactions' },
  { title: 'Rents', href: '/rents' },
  { title: 'Rewards', href: '/rewards' },
  { title: 'State Licenses', href: '/state-licenses' },
  { title: 'Users', href: '/users' },
  { title: 'Waitlisted Users', href: '/waitlisted-users' },
  { title: 'Web App Event Types', href: '/event-types' }
]

const partnerSidebarItems = [
  { title: 'Integration Partners', href: '/integration-partners'},
  { title: 'Invitations', href: '/partner-invitations'},
  { title: 'Affiliates', href: '/affiliates'},
  { title: 'Brokerages', href: '/brokerages'},
  { title: 'Agents', href: '/agents'},
  { title: 'Lenders', href: '/lender-partners'},
  { title: 'Property Managers', href: '/property-managers'},
  { title: 'Partner Users', href: '/partner-users'},
  { title: 'Partner Ratings', href: '/partner-ratings'},
  { title: 'Savers', href: '/partner-savers'}
]

const systemSidebarItems = [
  { title: 'Application Settings', href: '/application-settings' },
  { title: 'Blocklist', href: '/blocklist' },
  { title: 'Reward Settings', href: '/reward-settings' },
  { title: 'Feature Flags', href: '/feature-flags' },
  { title: 'Housing Price Imports', href: '/housing-price-imports' },
  { title: 'Sidekiq Dashboard', href: '/jobs' },
  { title: 'Proxy Dashboard', href: '/proxy' },
  { title: 'Webhook Services', href: '/webhook-services' }
]

const SidebarLogo = () => (
  <Image src="/wordmark.svg" alt="Workflow" height="34" width="115.49" />
)

const EnvironmentBanner = () => (
  <div className="px-6 mt-1">
    <Shelf valign="center" gap={2}>
      <div className="h-1.5 w-1.5 bg-amber-400 rounded-full" />

      <span className="text-white opacity-40 text-xs tracking-widest uppercase">
        {process.env.projectEnv || 'Development'}
      </span>
    </Shelf>
  </div>
)

const SettingsDropdown = ({ setEditingApiEndpoint }) => {
  const user = useSession()
  const router = useRouter()
  const { logout } = useAuth()

  const canEditEndpoint = process.env.projectEnv === 'staging'

  return (
    <Menu as="div" className="px-3 relative inline-block text-left mt-2">
      <div>
        <Menu.Button className="group w-full rounded-md px-3.5 py-2 text-sm text-left font-medium hover:bg-gray-800 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-900 focus:ring-indigo-600">
          <span className="flex w-full justify-between items-center">
            <span className="flex min-w-0 items-center justify-between space-x-3">
              <img
                className="w-6 h-6 bg-gray-300 rounded-full shrink-0"
                src={user.avatarUrl}
                alt={user.name}
              />
              <span className="text-gray-300 text-sm font-semibold truncate group-hover:text-gray-100">
                {user.name}
              </span>
            </span>

            <SelectorIcon
              className="shrink-0 h-5 w-5 text-gray-300 group-hover:text-gray-100"
              aria-hidden="true"
            />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
          {canEditEndpoint ? (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault()
                      setEditingApiEndpoint(true)
                    }}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    Change endpoint
                  </a>
                )}
              </Menu.Item>
            </div>
          ) : null}
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault
                    logout(() => router.push('/login'))
                  }}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Sign out
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

const Sidebar = (): JSX.Element => {
  const [open, setOpen] = useRecoilState(sidebarState)

  const [isEditingApiEndpoint, setEditingApiEndpoint] =
    React.useState<boolean>(false)

  const closeSidebar = () => {
    setOpen(false)
  }

  React.useLayoutEffect(() => {
    setOpen(false)
  }, [])

  return (
    <React.Fragment>
      {/* Mobile Sidebar */}
      <div className="lg:hidden" style={{ display: open ? 'block' : 'none' }}>
        <div className="fixed inset-0 flex z-40">
          <div className="fixed inset-0" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-900 bg-opacity-75"></div>
          </div>

          <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-900 mobile-sidebar-container">
            <OutsideClickHandler disabled={!open} onOutsideClick={closeSidebar}>
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={closeSidebar}
                >
                  <span className="sr-only">Close sidebar</span>
                  <Close size={25} />
                </button>
              </div>
              <div className="shrink-0 flex items-center px-4 mb-2">
                <Link href="/">
                  <a>
                    <SidebarLogo />
                  </a>
                </Link>
              </div>

              <EnvironmentBanner />

              <SettingsDropdown
                setEditingApiEndpoint={(value) => {
                  closeSidebar()
                  setEditingApiEndpoint(value)
                }}
              />

              <nav className="mt-3 shrink-0 h-full overflow-y-auto">
                <div className="px-2">
                  <div className="space-y-1 pb-10">
                    {sidebarItems.map((item, idx) => {
                      const { title, href } = item

                      return <SidebarItem key={idx} title={title} href={href} />
                    })}

                    <div className="mt-4 p-1 bg-gray-800 bg-opacity-50 rounded-xl">
                      <h3 className="px-3 mb-1 mt-3 text-xs font-semibold text-gray-200 uppercase tracking-wider">
                        Partners
                      </h3>

                      {partnerSidebarItems.map((item, idx) => {
                        const { title, href } = item

                        return (
                          <SidebarItem key={idx} title={title} href={href} />
                        )
                      })}
                    </div>

                    <div className="mt-4 p-1 bg-gray-800 bg-opacity-50 rounded-xl">
                      <h3 className="px-3 mb-1 mt-3 text-xs font-semibold text-gray-200 uppercase tracking-wider">
                        System
                      </h3>

                      {systemSidebarItems.map((item, idx) => {
                        const { title, href } = item

                        return (
                          <SidebarItem key={idx} title={title} href={href} />
                        )
                      })}
                    </div>
                  </div>
                </div>
              </nav>
            </OutsideClickHandler>
          </div>
        </div>
      </div>
      {/* End Mobile Sidebar */}

      {/* Desktop Sidebar */}
      <div className="hidden lg:flex lg:shrink-0">
        <div className="flex flex-col w-64 border-r border-gray-900 bg-gray-900 text-white pt-5">
          <Link href="/">
            <a className="px-6">
              <SidebarLogo />
            </a>
          </Link>

          <EnvironmentBanner />

          <SettingsDropdown setEditingApiEndpoint={setEditingApiEndpoint} />

          <div className="h-0 mt-2 flex-1 flex flex-col overflow-y-auto">
            <nav className="px-3 pb-5">
              {sidebarItems.map((item, idx) => {
                const { title, href } = item

                return <SidebarItem key={idx} title={title} href={href} />
              })}

              <div className="mt-4 p-1 bg-gray-800 bg-opacity-50 rounded-xl">
                <h3 className="px-3 mb-1 mt-3 text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  Partners
                </h3>

                {partnerSidebarItems.map((item, idx) => {
                  const { title, href } = item

                  return <SidebarItem key={idx} title={title} href={href} />
                })}
              </div>

              <div className="mt-4 p-1 bg-gray-800 bg-opacity-50 rounded-xl">
                <h3 className="px-3 mb-1 mt-3 text-xs font-semibold text-gray-200 uppercase tracking-wider">
                  System
                </h3>

                {systemSidebarItems.map((item, idx) => {
                  const { title, href } = item

                  return <SidebarItem key={idx} title={title} href={href} />
                })}
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/* End Desktop Sidebar */}

      {isEditingApiEndpoint ? (
        <ApiEndpointModal onDismiss={() => setEditingApiEndpoint(false)} />
      ) : null}
    </React.Fragment>
  )
}

export default Sidebar
