import React from 'react'

interface BadgeProps {
  color?: 'gray' | 'red' | 'emerald' | 'indigo'
}

const colorMap = {
  gray: 'text-gray-800',
  red: 'text-red-800',
  emerald: 'text-emerald-800',
  indigo: 'text-indigo-800'
}

const backgroundMap = {
  gray: 'bg-gray-100',
  red: 'bg-red-100',
  emerald: 'bg-emerald-100',
  indigo: 'bg-indigo-100'
}

const Badge: React.FC<BadgeProps> = ({ children, color }) => {
  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${backgroundMap[color]} ${colorMap[color]}`}
    >
      {children}
    </span>
  )
}

Badge.defaultProps = {
  color: 'gray'
}

export default Badge
