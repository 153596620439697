import React from 'react'
import classNames from 'classnames'

const themeClass = {
  default: 'bg-white',
  secondary: 'bg-gray-50'
}

const layouts = {
  padded: 'px-4 py-5 sm:p-6',
  flush: ''
}

type CardThemes = 'default' | 'secondary'

interface CardProps {
  layout?: 'padded' | 'flush'
  theme?: CardThemes
}

const Card: React.FC<CardProps> = ({ children, layout, theme }) => {
  return (
    <div
      className={classNames({
        'shadow md:rounded-lg': true,
        [themeClass[theme]]: true
      })}
    >
      <div
        className={classNames({
          [layouts[layout]]: true
        })}
      >
        {' '}
        {children}
      </div>
    </div>
  )
}

Card.defaultProps = {
  layout: 'padded',
  theme: 'default'
}

export default Card
