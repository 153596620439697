import React from 'react'
import Link from 'next/link'

import { Contact } from 'types/models'

interface ContactCellProps {
  contact: Contact
  withAvatar?: boolean
  onlyAvatar?: boolean
}

const ContactCell: React.FC<ContactCellProps> = (props) => {
  const { contact } = props

  if (!contact) {
    return null
  }

  return (
    <Link href={`/contact/${contact.id}`}>
      <a className="hover:text-indigo-600 flex items-center space-x-3">
          <span>
            {contact.firstName} {contact.lastName}
          </span>
      </a>
    </Link>
  )
}

export default ContactCell
