import React, { useMemo } from 'react'
import Link from 'next/link'
import classNames from 'classnames'

export const Tab: React.FC = ({ children }) => {
  return (
    <div className="border-b border-gray-200">
      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
        {children}
      </nav>
    </div>
  )
}

interface TabItemProps {
  active?: boolean
  disabled?: boolean
  href?: string
  onClick?(): void
}

export const TabItem: React.FC<TabItemProps> = ({
  active = false,
  disabled = false,
  href,
  onClick,
  children
}) => {
  const linkProps: { onClick?(): void } = {}
  if (onClick) {
    linkProps.onClick = onClick
  }

  const link = useMemo(
    () => (
      <a
        {...linkProps}
        className={classNames({
          'tab-item border-transparent text-gray-500 whitespace-nowrap py-5 px-1 border-b-2 font-semibold text-sm cursor-pointer':
            true,
          'border-indigo-500 text-indigo-600 hover:border-indigo-500 hover:text-indigo-600':
            active,
          'opacity-40 cursor-not-allowed': disabled,
          'hover:text-gray-700 hover:border-gray-300': !disabled
        })}
      >
        {children}
      </a>
    ),
    [children, linkProps]
  )

  if (onClick || disabled) {
    return link
  }

  return <Link href={href}>{link}</Link>
}
