import React from 'react'
import { useRecoilState, atom } from 'recoil'

import { Modal } from 'components/ui'

export const deactivateActionState = atom({
  key: 'deactivateActionState',
  default: null
})

interface DeactivateModalProps {
  handleDeactivate(id: number): void
}

const DeactivateModal = ({
  handleDeactivate
}: DeactivateModalProps): JSX.Element | null => {
  const [recordToDeactivate, setRecordToDeactivate] = useRecoilState(
    deactivateActionState
  )

  if (!recordToDeactivate) {
    return null
  }

  return (
    <Modal
      isOpen
      onDismiss={() => setRecordToDeactivate(null)}
      onConfirm={() => {
        handleDeactivate(recordToDeactivate.id)
        setRecordToDeactivate(null)
      }}
      title="Deactivate Record"
      body="Are you sure you want to deactivate this record?"
    />
  )
}

export default DeactivateModal
