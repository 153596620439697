import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { gray } from 'tailwindcss/colors'
import { AsyncPaginate } from 'react-select-async-paginate'
import { Control, Controller } from 'react-hook-form'
import { components } from 'react-select'
import compact from 'lodash/compact'
import keyBy from 'lodash/keyBy'
import omit from 'lodash/omit'
import uniq from 'lodash/uniq'

import useEffectAfterMount from 'hooks/useEffectAfterMount'

import { Spinner } from 'components/ui'
import { Close, Selector } from 'components/Icon'

const styles = {
  input: () => ({
    border: 'none',
    borderColor: 'transparent',
    boxShadow: 'none'
  })
}

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Selector size={16} color={gray[400]} />
  </components.DropdownIndicator>
)

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <Close color={gray[400]} size={16} />
  </components.ClearIndicator>
)

const LoadingIndicator = () => <Spinner size={4} />

interface Option extends Record<string, unknown> {
  value: React.ReactText
  label: string
}

export interface Group {
  label: string
  options: Option[]
}

export interface SelectAsyncProps<T extends unknown> {
  autoFocus?: boolean
  buildOptions?: (params: T | T[]) => Option[]
  control: Control
  onChange?(selected: Option): void
  onBlur?(): void
  fetchOptions?: (params: Record<string, unknown>) => any
  isClearable?: boolean
  name: string
  placeholder: string
  selectPredicate: string
  value?: React.ReactText | React.ReactText[]
}

function SelectAsync<T extends unknown>({
  value,
  selectPredicate,
  ...props
}: SelectAsyncProps<T>): React.ReactElement {
  const [inputValue, setInputValue] = useState('')
  const [loadedOptions, setLoadedOptions] = useState([])
  const [allSeenOptions, setAllSeenOptions] = useState([])

  const optionsByValue = useMemo(
    () => keyBy(allSeenOptions, (item) => (item as Option).value),
    [allSeenOptions]
  )

  const currentOptionsFromValue = useMemo(() => {
    if (Array.isArray(value)) {
      return compact(value.map((v) => optionsByValue[v]))
    }

    return optionsByValue[value]
  }, [optionsByValue, value])

  const fallbackOption = useMemo(() => {
    const newValue = {
      label: value,
      value
    }

    if (value) {
      return newValue
    }

    return null
  }, [value])

  const currentValue = currentOptionsFromValue || fallbackOption

  useEffectAfterMount(() => {
    setLoadedOptions([])
  }, [props.fetchOptions])

  const loadOptionByValue = useCallback(
    async (id) => {
      if (props.fetchOptions && props.buildOptions) {
        const params = { q: { id_eq: id } }

        const response = await props.fetchOptions(params)
        const options = props.buildOptions(response.data)
        setLoadedOptions(options)
        setAllSeenOptions((prevState) =>
          uniq([...(prevState as Option[]), ...options])
        )

        return options
      }

      return []
    },
    [props.fetchOptions]
  )

  const loadOptions = useCallback(
    async (
      currentInputValue: React.ReactText,
      prevItems: Option[] | Group[],
      { page }
    ) => {
      const params = { page, q: { [selectPredicate]: currentInputValue } }
      const response = await props.fetchOptions(params).catch(() => ({
        data: [],
        totalCount: 0
      }))

      const totalCount = Number(response.headers['total-count'])
      const options = props.buildOptions(response.data)
      setAllSeenOptions((prevState) =>
        uniq([...(prevState as Option[]), ...options])
      )

      const nextOptions = [...options, ...prevItems]
      setLoadedOptions(nextOptions)
      return {
        options,
        hasMore: totalCount > nextOptions.length,
        additional: {
          page: page + 1
        }
      }
    },
    [props.fetchOptions]
  )

  const onChange = useCallback(
    (selected) => {
      if (selected) {
        if (props.onChange) {
          props.onChange(selected.value)
        }
        return selected.value
      }

      if (props.onChange) {
        props.onChange(selected)
      }
      return selected
    },
    [props.onChange]
  )

  useEffect(() => {
    if (value) {
      loadOptionByValue(value)
    }
  }, [value])

  const filteredProps = {
    ...omit(props, ['control', 'value']),
    autoFocus: props.autoFocus,
    isClearable: props.isClearable,
    placeholder: props.placeholder,
    components: {
      IndicatorSeparator: () => null,
      ClearIndicator,
      DropdownIndicator,
      LoadingIndicator
    },
    onChange,
    inputValue,
    onInputChange: (currentInputValue) => setInputValue(currentInputValue),
    loadOptionByValue,
    loadedOptions,
    loadOptions,
    styles,
    additional: {
      page: 1
    }
  }

  return (
    <AsyncPaginate
      {...filteredProps}
      className="select-container"
      classNamePrefix="select"
      value={currentValue}
    />
  )
}

SelectAsync.defaultProps = {
  isClearable: true
}

function SelectAsyncControlled<T extends unknown>(
  props: SelectAsyncProps<T>
): React.ReactElement {
  return (
    <Controller
      {...props}
      name={props.name}
      control={props.control}
      defaultValue={props.value || null}
      render={({ onChange, onBlur, value, name }) => (
        <SelectAsync
          {...props}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          name={name}
        />
      )}
    />
  )
}

export { SelectAsyncControlled as default }
