import React from 'react'

interface Option {
  value: number | string
  text: string
}

export interface SelectProps {
  autoFocus?: boolean
  name: string
  options?: Option[]
  includeBlank?: boolean
  placeholder?: string
  defaultValue?: string
  onChange?(value: any): void
}

const Select = React.forwardRef((props: SelectProps, ref: any) => {
  const {
    autoFocus,
    includeBlank,
    name,
    placeholder,
    options,
    defaultValue,
    onChange
  } = props

  return (
    <div className="relative rounded-md shadow-sm w-full">
      <select
        ref={ref}
        name={name}
        onChange={onChange}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      >
        {includeBlank && (
          <option value="">Select a {placeholder || name}</option>
        )}
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          )
        })}
      </select>
    </div>
  )
})

Select.defaultProps = {
  includeBlank: true,
  options: []
}

export default Select
