import React from 'react'

interface CheckboxProps {
  defaultChecked?: boolean
  name?: string
}

const Checkbox = React.forwardRef(
  (props: CheckboxProps, ref: React.MutableRefObject<HTMLInputElement>) => (
    <div className="relative">
      <input
        {...props}
        id={props.name}
        type="checkbox"
        ref={ref}
        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      />
    </div>
  )
)

export default Checkbox
