import React from 'react'

const List: React.FC = ({ children }) => {
  return <dl className="divide-y divide-gray-200">{children}</dl>
}

interface ListItem {
  text: React.ReactElement | string | number
  value: React.ReactElement | string | number
  defaultValue?: React.ReactElement | string | number
}

const formattedValue = (value) => {
  if (value == null || value == '') {
    return '--'
  } else {
    return value
  }
}

const formattedDefault = (value) => {
  if (value == null || value == '') {
    return ''
  } 
  return <div><br></br><br></br><span className="font-bold">Default:</span> {value}</div>
}

const ListItem: React.FC<ListItem> = ({ text, value, defaultValue='' }) => {
  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-6">
      <dt className="text-sm font-medium text-gray-500">{text}</dt>

      <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span className="flex-grow empty-content">{formattedValue(value)}{formattedDefault(defaultValue)}</span>
      </dd>
    </div>
  )
}

export { List, ListItem }
