import React from 'react'
import { useRouter } from 'next/router'

import Button from '../Button/Button'
import Shelf from '../Shelf/Shelf'

interface FormActionProps {
  isSubmitting?: boolean
  onSubmit: () => void
  resourceName: string
}

const FormAction: React.FC<FormActionProps> = (props) => {
  const router = useRouter()
  const { isSubmitting, onSubmit, resourceName } = props

  return (
    <div className="px-6 pb-6">
      <Shelf align="right" gap={2}>
        <Button theme="link" onClick={() => router.back()}>
          Cancel
        </Button>

        <Button
          type="submit"
          theme="primary"
          onClick={onSubmit}
          loading={isSubmitting}
        >
          Save {resourceName}
        </Button>
      </Shelf>
    </div>
  )
}

export default FormAction
