import React, { Ref, useMemo } from 'react'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import orderBy from 'lodash/orderBy'

import Select, { SelectProps } from 'components/ui/Select/Select'

const statuses = {
  kyc_in_progress: 0,
  kyc_pending: 1,
  government_id_requested: 2,
  reupload_government_id: 3,
  reviewing_government_id: 4,
  user_account_declined: 5,
  user_account_approved: 6,
  savings_account_in_progress: 7,
  active_savings_account: 8,
  account_issue: 9,
  user_account_closed: 10,
  government_id_uploaded: 11,
  ssn_card_requested: 12,
  ssn_card_uploaded: 13,
  reupload_ssn_card: 14,
  reviewing_ssn_card: 15,
  admin_locked: 16
}

const UserAccountStatusSelect = React.forwardRef(
  (props: SelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const options = useMemo(() => {
      const array = []
      Object.keys(statuses).forEach((status) => {
        array.push({
          value: statuses[status],
          text: startCase(toLower(status))
        })
      })

      return orderBy(array, ['text'])
    }, [])

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options} {...props} ref={ref} />
      </div>
    )
  }
)

export default UserAccountStatusSelect
