import React from 'react'

interface Props {
  color?: string
  size?: number
}

const Download = React.forwardRef(
  (
    { color = 'currentColor', size = 16, ...rest }: Props,
    ref: React.LegacyRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        className="text-white"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        stroke={color}
        aria-hidden="true"
        {...rest}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
        />
      </svg>
    )
  }
)

export default Download
