type IObject = {
  [key: string]: string | number | boolean
}

const save = (key: string, value: IObject | string) => {
  if (typeof value === 'string') {
    localStorage.setItem(key, value)
  } else {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

const load = (key: string) => {
  if (typeof window === 'undefined') {
    return null
  }

  const item = localStorage.getItem(key)

  try {
    return JSON.parse(item)
  } catch {
    return item
  }
}

const remove = (key: string) => {
  localStorage.removeItem(key)
}

export { save, load, remove }
