import React from 'react'
import classNames from 'classnames'

import Spinner from 'components/ui/Spinner/Spinner'

type Icons = 'search'

interface InputProps {
  autoFocus?: boolean
  defaultValue?: string
  hidden?: boolean
  icon?: Icons
  loading?: boolean
  name?: string
  placeholder?: string
  readonly?: boolean
  value?: string
  onChange?: (event: React.BaseSyntheticEvent) => void
  type?: string
}

const icons = {
  search: (
    <svg
      className="mr-3 h-4 w-4 text-gray-400"
      x-description="Heroicon name: search"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

const Input = React.forwardRef(
  (props: InputProps, ref: React.MutableRefObject<HTMLInputElement>) => {
    const { icon, loading, hidden, ...filteredProps } = props

    return (
      <div
        className={classNames({
          'relative w-full rounded-md shadow-sm': true
        })}
      >
        {icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {icons[icon]}
          </div>
        )}

        <input
          {...filteredProps}
          ref={ref}
          className={classNames({
            'block w-full sm:text-sm border-gray-300 shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500':
              true,
            'pl-8': typeof icon !== 'undefined',
            'pr-10': typeof loading !== 'undefined',
            'hidden': typeof hidden !== 'undefined'
          })}
        />

        {loading && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <Spinner />
          </div>
        )}
      </div>
    )
  }
)

Input.defaultProps = {
  type: 'text'
}

export default Input
