import React, { useCallback, useContext } from 'react'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'

import ToastContext from 'contexts/ToastContext'
import { toSentence } from 'lib/util'

const useToastContext = () => useContext(ToastContext)

const useToast = () => {
  const { addToast, dismissToast } = useToastContext()

  const success = useCallback((message, options = {}) => {
    const id = addToast({
      type: 'success',
      message,
      ...options
    })

    return id
  }, [])

  const error = useCallback((message, options = {}) => {
    addToast({
      type: 'error',
      message,
      ...options
    })
  }, [])

  const validation = useCallback((resource, errors = {}) => {
    const message = (
      <span>Please fix the following errors before saving the {resource}:</span>
    )
    const body = (
      <div className="flex-1">
        <div className="divide-y divide-gray-100 toast-max-height">
          {Object.keys(errors).map((key) => {
            return (
              <div key={key} className="py-3">
                <p className="text-sm text-gray-600">
                  {startCase(lowerCase(key))} {toSentence(errors[key])}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    )
    const errorLength = Object.keys(errors).length
    error(message, {
      duration: errorLength * 5000,
      body
    })
  }, [])

  const loading = useCallback((message, options = {}) => {
    const id = addToast({
      type: 'loading',
      message,
      ...options
    })

    return id
  }, [])

  return { success, error, loading, validation, dismiss: dismissToast }
}

export default useToast
