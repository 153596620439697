import React from 'react'
import Link from 'next/link'
import classNames from 'classnames'

import { ChevronLeft, ChevronRight } from 'components/Icon'

interface Item {
  text: string
  href: string
}

interface BreadcrumbProps {
  items: Item[]
}

const Breadcrumbs = ({ items }: BreadcrumbProps): JSX.Element => {
  const numberOfBreadcrumbs = items.length
  const backBreadcrumb = items[numberOfBreadcrumbs - 2]

  return (
    <div>
      {items.length >= 2 && (
        <nav className="sm:hidden" aria-label="Back">
          <Link href={backBreadcrumb.href}>
            <a className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
              <ChevronLeft />
              Back
            </a>
          </Link>
        </nav>
      )}

      <nav className="hidden sm:flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          {items.map((item, idx) => {
            return (
              <li key={idx}>
                <div className="flex items-center">
                  {idx > 0 && <ChevronRight />}

                  <Link href={item.href}>
                    <a
                      className={classNames({
                        'text-sm font-medium capitalize text-gray-500 hover:text-gray-700':
                          true,
                        'ml-4': idx !== 0
                      })}
                    >
                      {item.text}
                    </a>
                  </Link>
                </div>
              </li>
            )
          })}
        </ol>
      </nav>
    </div>
  )
}

export default Breadcrumbs
