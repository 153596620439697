import React, { Ref } from 'react'

import Select, { SelectProps } from 'components/ui/Select/Select'

const RewardTypeSelect = React.forwardRef(
  (props: SelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const options = [
      { value: 'Rewards::BlogCompleted', text: 'Blog Completed' },
      { value: 'Rewards::CreateHomebuyerProfile', text: 'Create Homebuyer Profile' },
      { value: 'Rewards::CreditTracking', text: 'Credit Tracking' },
      { value: 'Rewards::DepositStreak', text: 'Deposit Streak' },
      { value: 'Rewards::DownPaymentGoalCreated', text: 'Down Payment Goal Created' },
      { value: 'Rewards::GravyPlus', text: 'Gravy Plus' },
      { value: 'Rewards::HomebuyerProfileUpdate', text: 'Update Homebuyer Profile'},
      { value: 'Rewards::LinkedAccountAdded', text: 'Linked Account Added' },
      { value: 'Rewards::ManuallyTrackedAssetAdded', text: 'Add Manually Tracked Asset' },
      { value: 'Rewards::MonthlySignIn', text: 'Monthly Sign In' },
      { value: 'Rewards::MortgageAdded', text: 'Mortgage Added' },
      { value: 'Rewards::ProfilePhotoAdded', text: 'Profile Photo Added' },
      { value: 'Rewards::ReferredUser', text: 'Referred User' },
      { value: 'Rewards::RegistrationCompleted', text: 'Registration Completed' },
      { value: 'Rewards::RentAdded', text: 'Rent Added' },
      { value: 'Rewards::RentPaid', text: 'Rent Paid' },
      { value: 'Rewards::RentRenewal', text: 'Rent Renewal' },
      { value: 'Rewards::SavingsCreated', text: 'Savings Created' },
      { value: 'Rewards::TrackCompleted', text: 'Track Completed' },
      { value: 'Rewards::UseRewardCalculator', text: 'Use Rewards Calculator'},
      { value: 'Rewards::WildCard', text: 'Wild Card'}
    ]

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options} {...props} ref={ref} />
      </div>
    )
  }
)

export default RewardTypeSelect
