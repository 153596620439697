import React from 'react'

import Layout from '../Layout/Layout'
import { Spinner } from '../ui'

const LoadingLayout = (): JSX.Element => (
  <Layout>
    <div className="h-screen w-full flex items-center justify-center">
      <Spinner color="indigo" />
    </div>
  </Layout>
)

export default LoadingLayout
