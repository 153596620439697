import React, { Ref, useCallback, useEffect, useState } from 'react'
import useIsMounted from 'ismounted'

import { API } from 'lib/gravy'
import Select, { SelectProps } from 'components/ui/Select/Select'
import { LenderPartner } from 'types/models'

const LenderPartnerSelect = React.forwardRef(
  (props: SelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const [lenderPartners, setLenders] = useState<LenderPartner[]>()
    const isMounted = useIsMounted()

    const options = useCallback(() => {
      return lenderPartners.map((lenderPartner) => {
        return { value: lenderPartner.id, text: `${lenderPartner.partnerDetail.companyName}` }
      })
    }, [lenderPartners])

    useEffect(() => {
      API.get('lender_partners?q[s]=partner_detail_company_name+asc').then((response) => {
        if (isMounted.current) {
          setLenders(response.data)
        }
      })
    }, [])

    if (!lenderPartners) {
      return <Select {...props} />
    }

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options()} {...props} ref={ref} />
      </div>
    )
  }
)

export default LenderPartnerSelect
