import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'

import {
  apiIntercept,
  LOCAL_STORAGE_API_URL,
  LOCAL_STORAGE_TOKEN,
  LOCAL_STORAGE_USER
} from 'lib/gravy'
import { load, remove, save } from 'lib/storage'

import { Spinner } from 'components/ui'

const UNAUTH_PAGES = ['/login', '/secure', '/verify']

const AuthContext = createContext(null)

interface User {
  name: string
  avatarUrl: string
  expirationTime: number
}

const removeLocalStorageItems = () => {
  remove(LOCAL_STORAGE_USER)
  remove(LOCAL_STORAGE_TOKEN)
}

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const token = load(LOCAL_STORAGE_TOKEN)
    const session = load(LOCAL_STORAGE_USER)

    if (token && session) {
      setUser(session)
    }

    setLoading(false)
  }, [])

  const login = async (
    { name, token, expirationTime, avatarUrl },
    callback
  ) => {
    const session = { name, avatarUrl, expirationTime }

    save(LOCAL_STORAGE_TOKEN, token)
    save(LOCAL_STORAGE_USER, session)
    save(LOCAL_STORAGE_API_URL, process.env.gravyApiUrl)
    setUser(session)
    Sentry.setUser({ name })

    if (callback) {
      callback()
    }
  }

  const logout = (callback) => {
    removeLocalStorageItems()
    setUser(null)
    Sentry.configureScope((scope) => scope.setUser(null))

    if (callback) {
      callback()
    }
  }

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: !!user, user, login, logout, loading }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)

export const ProtectedRoute = ({ children }) => {
  apiIntercept()

  const { isAuthenticated, user, loading } = useAuth()
  const router = useRouter()

  const isUnauthPage = UNAUTH_PAGES.includes(router.pathname)

  const isTokenExpired = useMemo(() => {
    if (!user || !user.expirationTime) {
      return true
    }

    const now = new Date().getTime()
    return now > user.expirationTime
  }, [user])

  if (loading) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <Spinner color="indigo" />
      </div>
    )
  }

  if (isUnauthPage) {
    return children
  }

  if (!isAuthenticated || isTokenExpired) {
    removeLocalStorageItems()
    if (router.asPath === '/') {
      router.push('/login')
    } else {
      router.push(`/login?redirect=${router.asPath}`)
    }
    return null
  }

  return children
}
