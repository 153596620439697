import React, { Ref } from 'react'

import Select, { SelectProps } from 'components/ui/Select/Select'

const OperatingSystemSelect = React.forwardRef(
  (props: SelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const options = [
      { value: 'android', text: 'Android' },
      { value: 'ios', text: 'iOS' }
    ]

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options} {...props} ref={ref} />
      </div>
    )
  }
)

export default OperatingSystemSelect
