import React from 'react'

import Select from 'components/selects/Select'
import { FinancialAccount } from 'types/models'
import { API } from 'lib/gravy'
import { Control } from 'react-hook-form'

interface FinancialAccountSelectProps {
  autoFocus?: boolean
  name: string
  placeholder?: string
  control: Control
  isClearable?: boolean
}

const FinancialAccountSelect = (props: FinancialAccountSelectProps): JSX.Element => {
  const fetchOptions = (params) => {
    params.per = 10
    params.q.s = 'name ASC'
    return API.get('financial_accounts', { params })
  }

  const buildOptions = (options) => {
    return options.map((option: FinancialAccount) => ({
      value: option.id,
      label: option.name
    }))
  }

  return (
    <Select
      {...props}
      placeholder={props.placeholder || 'Select a financial account' }
      buildOptions={buildOptions}
      fetchOptions={fetchOptions}
      selectPredicate="name_cont"
    />
  )
}

export default FinancialAccountSelect
