const idCounter = {}

const uniqueId = (prefix: string): string => {
  if (!idCounter[prefix]) {
    idCounter[prefix] = 0
  }

  const id = ++idCounter[prefix]

  return `${prefix}${id}`
}

export default uniqueId
