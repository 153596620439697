import React, { LegacyRef, useCallback } from 'react'
import classnames from 'classnames'

import useButtonStyles from './useButtonStyles'
import Shelf from 'components/ui/Shelf/Shelf'

type ButtonSizes = 'small' | 'medium' | 'large' | 'xlarge'
type ButtonThemes = 'primary' | 'secondary' | 'link' | 'danger' | 'transparent'

export interface ButtonProps {
  className?: string
  disabled?: boolean
  loading?: boolean
  size?: ButtonSizes
  theme?: ButtonThemes
  type?: 'button' | 'submit' | 'reset'
  onClick?: (event: React.MouseEvent) => void
  onMouseDown?: (event: React.MouseEvent) => void
  icon?: JSX.Element
}

const Spinner = () => (
  <svg
    className="animate-spin h-4 w-4 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
)

const Button: React.FC<ButtonProps> = React.forwardRef(
  (props, ref: LegacyRef<HTMLButtonElement>) => {
    const {
      children,
      className,
      onClick,
      onMouseDown,
      icon,
      loading,
      size,
      theme,
      type,
      disabled
    } = props
    const disabledOrLoading = disabled || loading
    const themeClass = useButtonStyles({
      size,
      theme,
      disabled: disabledOrLoading
    })

    const renderChildren = useCallback(() => {
      if (loading) {
        return (
          <Shelf valign="center" gap={2}>
            <Spinner />
            {children}
          </Shelf>
        )
      }

      if (icon) {
        return (
          <Shelf valign="center" gap={1}>
            {icon}
            {children}
          </Shelf>
        )
      }

      return children
    }, [icon, children, loading])

    return (
      <button
        onClick={onClick}
        onMouseDown={onMouseDown}
        ref={ref}
        type={type}
        disabled={disabledOrLoading}
        className={classnames({
          [themeClass]: true,
          [className]: typeof className !== 'undefined'
        })}
      >
        {renderChildren()}
      </button>
    )
  }
)

Button.defaultProps = {
  disabled: false,
  size: 'medium',
  theme: 'secondary',
  type: 'button'
}

export default Button
