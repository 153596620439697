import React from 'react'

import { Badge } from '../ui'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const Currency = ({ value }: { value: number }): JSX.Element => {
  if (!value) {
    return <>--</>
  }

  return <Badge>{formatter.format(value)}</Badge>
}

export default Currency
