import { API } from 'lib/gravy'
import { useCallback } from 'react'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'

import { FormErrors } from 'types'

import useToast from './useToast'

interface IArguments {
  path: string
  resourceName: string
  afterUpdateSuccess?(): void
  afterUpdateFailure?(errors: FormErrors): void
}

const useUpdateAction = ({
  path,
  resourceName,
  afterUpdateSuccess,
  afterUpdateFailure
}: IArguments) => {
  const toast = useToast()
  const resource = startCase(lowerCase(resourceName))

  const handleUpdate = useCallback(
    (params) => {
      API.patch(path, params)
        .then(() => {
          toast.success(`${resource} was successfully updated!`)

          if (afterUpdateSuccess) {
            afterUpdateSuccess()
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            toast.validation(resource, error.response.data.messages)

            if (afterUpdateFailure) {
              afterUpdateFailure(error.response.data.messages)
            }
          } else if (
            error.response.status === 500 ||
            error.response.status === 503
          ) {
            toast.error(
              'Unable to process your request. Please try again later.'
            )

            if (afterUpdateFailure) {
              afterUpdateFailure({})
            }
          }
        })
    },
    [path, resourceName, afterUpdateSuccess, afterUpdateFailure]
  )

  return handleUpdate
}

export default useUpdateAction
