import React from 'react'
import Link from 'next/link'

import { Partner } from 'types/models'

interface PartnerCellProps {
  partner: Partner
}

const PartnerCell: React.FC<PartnerCellProps> = (props) => {
  const { partner } = props

  if (!partner) {
    return null
  }

  return (
    <Link href={`lender-partner/${partner.id}`}>
      <a className="hover:text-indigo-600 flex items-center space-x-3">
        <span>
          {partner.name}
        </span>
      </a>
    </Link>
  )
}

export default PartnerCell
