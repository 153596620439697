import React, { Fragment, useCallback, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from 'react-hook-form'
import { gray } from 'tailwindcss/colors'

import { Close, InfoCircle } from 'components/Icon'
import { API } from 'lib/gravy'
import { Button, FormGroup, Shelf } from 'components/ui'
import useToast from 'hooks/useToast'

interface WaitlisedUsersImportDialogProps {
  open: boolean
  onClose(): void
  onSuccess(): void
}

const defaultValues = {
  file: null
}

const WaitlisedUsersImportDialog = (
  props: WaitlisedUsersImportDialogProps
): JSX.Element => {
  const { open, onSuccess, onClose } = props

  const toast = useToast()

  const [submitting, setSubmitting] = useState<boolean>(false)

  const { register, handleSubmit, reset } = useForm({
    defaultValues
  })

  const onSubmit = useCallback(async (data) => {
    setSubmitting(true)

    const formData = new FormData()
    const file = data.file[0]

    if (file) {
      formData.append('file', file)
    }

    API.post('import/waitlisted_users', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((response) => {
        const { createdRecords, invalidRecords } = response.data

        setSubmitting(false)
        onSuccess()
        reset(defaultValues)
        onClose()

        if (invalidRecords.length > 0) {
          const count =
            invalidRecords.length === 1
              ? '1 record'
              : `${invalidRecords.length} records`
          toast.error(
            <span>
              The following <span className="text-red-600">{count}</span> failed
              to be created.
            </span>,
            {
              duration: Infinity,
              body: (
                <div className="flex-1">
                  <div className="divide-y divide-gray-100 toast-max-height">
                    {invalidRecords.map((invalidRecord) => {
                      return (
                        <div key={invalidRecord.line} className="py-3">
                          <p className="text-sm text-gray-400 mb-1 italic">
                            Line: {invalidRecord.line}
                          </p>
                          <p className="text-sm text-gray-600">
                            {invalidRecord.messages}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            }
          )
        } else {
          const count =
            createdRecords.length === 1
              ? '1 Waitlisted User'
              : `${createdRecords.length} Waitlisted Users`
          toast.success(`Successfully imported ${count}`)
        }
      })
      .catch(() => {
        toast.error('Failed to import CSV')
        setSubmitting(false)
        reset(defaultValues)
        onClose()
      })
  }, [])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden z-50"
        open={open}
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 md:pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-full md:max-w-md">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
                >
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div className="py-6 px-4 bg-indigo-700 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          Upload Waitlisted Users
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <Close size={20} />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          Get started by filling in the information below.
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between">
                      <div className="p-4">
                        <div className="rounded-md bg-gray-50 p-4 mb-4">
                          <div className="flex">
                            <div className="shrink-0">
                              <InfoCircle
                                aria-hidden="true"
                                color={gray[500]}
                              />
                            </div>
                            <div className="ml-3 flex-1 md:flex md:justify-between">
                              <p className="text-sm text-gray-700">
                                Before getting started, take a look at the
                                template CSV to familiarize yourself with the
                                correct headers.
                              </p>
                              <p className="mt-3 text-sm md:mt-0 md:ml-6">
                                <a
                                  target="_blank"
                                  href="/waitlisted-users-template.csv"
                                  download="template.csv"
                                  className="whitespace-nowrap font-medium text-gray-700 hover:text-gray-600"
                                >
                                  Download{' '}
                                  <span aria-hidden="true">&rarr;</span>
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>

                        <FormGroup labelText="File" horizontal={false}>
                          <input
                            ref={register}
                            type="file"
                            name="file"
                            accept=".csv"
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="shrink-0 px-4 py-4 flex justify-end">
                    <Shelf gap={2} align="right">
                      <Button theme="link" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button
                        theme="primary"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        disabled={submitting}
                      >
                        Upload
                      </Button>
                    </Shelf>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default WaitlisedUsersImportDialog
