import React from 'react'
import { Dialog } from '@reach/dialog'
import classNames from 'classnames'

const sizes = {
  medium: 'max-w-lg',
  large: 'max-w-4xl'
}

interface ModalProps {
  body: React.ReactNode
  confirmButtonColor?: 'indigo' | 'red'
  isOpen: boolean
  onConfirm?(): void
  onDismiss(): void
  size?: 'medium' | 'large'
  title: string
}

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    confirmButtonColor = 'red',
    size,
    title,
    body,
    onConfirm,
    onDismiss,
    ...filteredProps
  } = props

  return (
    <Dialog
      {...filteredProps}
      onDismiss={onDismiss}
      aria-label="Modal"
      className={classNames({
        'relative rounded-lg': true,
        [sizes[size]]: true
      })}
    >
      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          onClick={onDismiss}
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <span className="sr-only">Close</span>
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      <div className="mt-3 text-center sm:mt-0 sm:text-left">
        <h3
          className="text-lg leading-6 font-medium text-gray-900"
          id="modal-headline"
        >
          {title}
        </h3>
        <div className="mt-2">
          {typeof body === 'string' ? (
            <p className="text-gray-500">{body}</p>
          ) : (
            body
          )}
        </div>
      </div>

      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        {onConfirm && (
          <button
            type="button"
            onClick={onConfirm}
            className={classNames({
              'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm':
                true,
              'bg-red-600 hover:bg-red-700 focus:ring-red-500':
                confirmButtonColor === 'red',
              'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500':
                confirmButtonColor === 'indigo'
            })}
          >
            Confirm
          </button>
        )}

        <button
          onClick={onDismiss}
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
        >
          Cancel
        </button>
      </div>
    </Dialog>
  )
}

Modal.defaultProps = {
  isOpen: false,
  size: 'medium'
}

export default Modal
