import React, { Fragment, useCallback, useState  } from 'react'
import { useToast } from 'hooks'
import { useForm } from 'react-hook-form'
import { FormErrors } from 'types'
import { API } from 'lib/gravy'
import { Dialog, Transition } from '@headlessui/react'
import Shelf from './ui/Shelf/Shelf'
import { Button, FormGroup } from './ui'
import { Close } from './Icon'

interface FileUploadDialogProps {
  open: boolean
  uploadable: any
  uploadableName: string
  displayName: string
  path: string
  onClose(): void
  onSuccess(): void
}

const defaultValues = {}

const FileUploadDialog = (props: FileUploadDialogProps): JSX.Element => {
  const { open, onSuccess, onClose, displayName, uploadableName, path } = props

  const toast = useToast()

  const [submitting, setSubmitting] = useState<boolean>(false)
  const [formErrors, setErrors] = useState<FormErrors>({})

  const { register, handleSubmit, reset } = useForm({
    defaultValues
  })

  const onSubmit = useCallback(async (data) => {
    setSubmitting(true)

    const formData = new FormData()
    const file = data[uploadableName][0]

    if (file) {
      formData.append(uploadableName, file)
    }

    API.post(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {
        toast.success('File was created successfully')
        setSubmitting(false)
        setErrors({})
        reset(defaultValues)
        onClose()
        onSuccess()
      })
      .catch((error) => {
        toast.error('Failed to upload file')
        const {
          status,
          data: { messages }
        } = error.response

        if (status === 422) {
          setSubmitting(false)
          setErrors(messages)
        }
      })
  }, [])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed inset-0 overflow-hidden z-50'
        open={open}
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 md:pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-full md:max-w-md">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
                >
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div className="py-6 px-4 bg-indigo-700 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          Add {displayName}
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <Close size={20} />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          Get started by filling in the information below.
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between">
                      <div className="p-4">
                        <FormGroup
                          labelText={displayName}
                          horizontal={false}
                          errors={formErrors['uploadableName']}
                        >
                          <input
                            ref={register({
                              required: true
                            })}
                            type="file"
                            name={uploadableName}
                            accept="image/*"
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="shrink-0 px-4 py-4 flex justify-end">
                    <Shelf gap={2} align="right">
                      <Button theme="link" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button
                        theme="primary"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        disabled={submitting}
                      >
                        Add File
                      </Button>
                    </Shelf>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default FileUploadDialog
