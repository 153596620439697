import React from 'react'
import { emerald, red } from 'tailwindcss/colors'

import { Check, Close } from 'components/Icon'

interface BooleanCellProps {
  value: boolean
}

const BooleanCell = ({ value }: BooleanCellProps): React.ReactElement =>
  value ? <Check color={emerald[500]} /> : <Close color={red[500]} />

export default BooleanCell
