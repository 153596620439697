import { useMutation } from '@tanstack/react-query'

import { API } from 'lib/gravy'
import useToast from './useToast'

interface UseDestroyActionProps {
  onSuccess?(): void
  path: string
  resourceName: string
}

interface UseDestroyActionResponse {
  handleDestroy(id: number): void
  isRecordBeingDestroyed(id: number): void
}

const useDestroyAction = ({
  path,
  resourceName,
  onSuccess
}: UseDestroyActionProps): UseDestroyActionResponse => {
  const toast = useToast()

  const destroyRecord = useMutation(
    (id: number) => API.delete(`${path}/${id}`),
    {
      onSuccess: () => {
        toast.success(`The ${resourceName} record was successfully deleted.`)

        if (onSuccess) {
          onSuccess()
        }
      },
      onError: () => {
        toast.error(`Failed to delete the ${resourceName} record.`)
      }
    }
  )

  const handleDestroy = (id: number) => destroyRecord.mutate(id)

  const isRecordBeingDestroyed = (id: number) =>
    destroyRecord.isLoading && destroyRecord.variables === id

  return {
    handleDestroy,
    isRecordBeingDestroyed
  }
}

export default useDestroyAction
