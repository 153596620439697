import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import qs from 'qs'

import { useAuth } from 'contexts/AuthContext'

import useToast from 'hooks/useToast'
import { load } from 'lib/storage'

export const LOCAL_STORAGE_TOKEN = 'gravyAccessToken'
export const LOCAL_STORAGE_USER = 'gravyUser'
export const LOCAL_STORAGE_GOOGLE_RESPONSE = 'gravyGoogleResponse'
export const LOCAL_STORAGE_API_URL = 'gravyApiUrl'

export const API = axios.create({
  baseURL: load(LOCAL_STORAGE_API_URL) || process.env.gravyApiUrl,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json'
  }
})

export const apiIntercept = (): void => {
  const toast = useToast()
  const { logout } = useAuth()

  API.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      const token = localStorage.getItem(LOCAL_STORAGE_TOKEN)

      if (token) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${token}`
        }
      }

      return config
    },
    (error) => Promise.reject(error)
  )

  API.interceptors.request.use((config: AxiosRequestConfig) => {
    config.paramsSerializer = (params) => {
      return qs.stringify(params, {
        arrayFormat: 'brackets',
        format: 'RFC1738'
      })
    }

    return config
  })

  // Axios middleware to convert all api requests to snake_case
  API.interceptors.request.use((config: AxiosRequestConfig) => {
    if (config.headers['Content-Type'] === 'multipart/form-data') {
      return config
    }

    const newConfig = { ...config }

    if (config.params) {
      newConfig.params = decamelizeKeys(config.params, (key, convert) => {
        const keys = ['referrable_of_Partner_type_eq', 'referrable_of_Partner_type_id_eq']
        return keys.includes(key) ? key : convert(key)
      })
    }

    if (config.data) {
      newConfig.data = decamelizeKeys(config.data)
    }
    return newConfig
  })

  API.interceptors.response.use(
    (response: AxiosResponse) => {
      if (response.data) {
        response.data = camelizeKeys(response.data)
      }

      return response
    },
    (error: AxiosError) => {
      if (!error.response) {
        return Promise.reject(error)
      }

      if (error.response.status === 401) {
        toast.error('Please sign in to continue using the app.')
        logout()
        return null
      } else if (error.response.status === 422) {
        error.response.data = camelizeKeys(error.response.data)
      }

      return Promise.reject(error)
    }
  )
}

export default { LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_USER, API, apiIntercept }
