import React, { Ref } from 'react'

import Select, { SelectProps } from 'components/ui/Select/Select'

const UnderReviewSelect = React.forwardRef(
  (props: SelectProps, ref: Ref<HTMLInputElement>): JSX.Element => {
    const options = [
      { value: 'true', text: 'Yes' },
      { value: 'false', text: 'No' }
    ]

    return (
      <div className="relative rounded-md shadow-sm w-full">
        <Select options={options} {...props} ref={ref} />
      </div>
    )
  }
)

export default UnderReviewSelect
