import React from 'react'
import classNames from 'classnames'

const colors = {
  gray: 'text-gray-400',
  indigo: 'text-indigo-800'
}
interface SpinnerProps {
  color: 'gray' | 'indigo'
  size?: 1 | 2 | 3 | 4 | 5
}

const Spinner = (props: SpinnerProps): JSX.Element => {
  const { color, size } = props

  return (
    <svg
      className={classNames({
        'animate-spin': true,
        [colors[color]]: true,
        'h-5 w-5': typeof size === 'undefined',
        [`h-${size} w-${size}`]: typeof size == 'number'
      })}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  )
}

Spinner.defaultProps = {
  color: 'gray'
}

export default Spinner
