import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { ParsedUrlQuery } from 'querystring'
import camelCase from 'lodash/camelCase'

import { API } from 'lib/gravy'

export const getQuery = async (path: string, params = {}) => {
  const { data, headers } = await API.get(path, { params })

  const totalCount = headers['total-count']

  return {
    result: data,
    totalCount
  }
}

type IndexQueryArgs = {
  path: string
  page: number
  query: ParsedUrlQuery | any
}

type IndexQueryResponse = UseQueryResult<{
  result: []
  totalCount: number
}>

const useIndexQuery = ({
  path,
  page,
  query
}: IndexQueryArgs): IndexQueryResponse => {
  const key = camelCase(path)
  const params = {
    page,
    q: {
      ...query
    }
  }

  return useQuery([key, params], () => getQuery(path, params), {
    keepPreviousData: true
  })
}

export default useIndexQuery
