import React from 'react'
import { format as formatTime, formatRelative, parseISO } from 'date-fns'

const formats = {
  short: 'MMM d, yyyy',
  long: 'MMM d, yyyy \'at\' h:mm aa'
}

type TimeFormats = 'short' | 'long' | 'relative'

interface Props {
  value: string
  format?: TimeFormats
}

const Time = ({ value, format }: Props): JSX.Element => {
  if (!value) {
    return null
  }

  if (format === 'relative') {
    return <span>{formatRelative(parseISO(value), new Date())}</span>
  }

  return <span>{formatTime(parseISO(value), formats[format])}</span>
}

Time.defaultProps = {
  format: 'short'
}

export default Time
